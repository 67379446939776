<template>
    <div>
      <!-- modal -->
      <b-modal
        id="trademarkBulletinCheckFormModal"
        centered
        size="xl"
        no-close-on-backdrop
        ref="trademarkBulletinCheckFormModal"
        :title=" $t('trademarkBulletin.trademark_bulletin_info')"
        @ok.prevent="addOrUpdate"
        hide-footer
      >
      <b-row>
        <!--Trademark--> 
          <b-col>
            <b-card > 
                <b-card-text>
                  {{$t('trademarkBulletin.bulletin_trademark_title') }}                
                </b-card-text>            
              <b-list-group >
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('trademarkBulletin.application_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.applicationNo != this.tpmkApplicationNo" style="color:red">
                            {{applicationNo}}
                          </b-card-text>
                          <b-card-text v-else-if="this.applicationNo == this.tpmkApplicationNo">
                            {{applicationNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('trademarkBulletin.application_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.applicationDate != this.tpmkApplicationDate" style="color:red">
                            {{this.$formatFn(applicationDate)}}
                          </b-card-text>
                          <b-card-text v-else-if="this.applicationDate == this.tpmkApplicationDate">
                            {{this.$formatFn(applicationDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-12 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('trademarkBulletin.name') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text :style="[ this.name != this.tpmkName ? { 'color': 'red' } :'']">
                            {{name}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-12 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('trademarkBulletin.nice_classes') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text :style="[ this.niceClasses != this.tpmkNiceClasses ? { 'color': 'red' } :'']">
                            {{niceClasses}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              Referans No
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{this.recordNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              İlgili
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{this.employee}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
              </b-list-group>
            </b-card>
          </b-col>
          <!--TPMK Trademark--> 
          <b-col>
            <b-card :title=" $t('trademarkBulletin.tpmk_bulletin_trademark_title') ">
              <b-list-group>
                <b-row>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('trademarkBulletin.tpmk_application_no') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.tpmkApplicationNo != this.applicationNo" style="color:red">
                            {{tpmkApplicationNo}}
                          </b-card-text>
                          <b-card-text v-else-if="this.tpmkApplicationNo == this.applicationNo">
                            {{tpmkApplicationNo}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                  <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('trademarkBulletin.tpmk_application_date') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text v-if="this.tpmkApplicationDate != this.applicationDate" style="color:red">
                            {{this.$formatFn(tpmkApplicationDate)}}
                          </b-card-text>
                          <b-card-text v-else-if="this.tpmkApplicationDate == this.applicationDate">
                            {{this.$formatFn(tpmkApplicationDate)}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-12 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('trademarkBulletin.name') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text :style="[ this.name != this.tpmkName ? { 'color': 'red' } :'']">
                            {{tpmkName}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-12  mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('trademarkBulletin.nice_classes') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text :style="[ this.niceClasses != this.tpmkNiceClasses ? { 'color': 'red' } :'']">
                            {{tpmkNiceClasses}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-md-12">
                    <b-list-group-item class="flex-column align-items-start">
                      <b-row>
                        <b-col>
                          <div class="d-flex w-100 justify-content-between">
                            <h6>
                              {{ $t('trademarkBulletin.tpmk_attorney') }}
                            </h6>
                          </div>
                        </b-col>
                        <b-col>
                          <b-card-text>
                            {{tpmkAttorney}}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-col>
                </b-row>
              </b-list-group>
            </b-card>
          </b-col>
      </b-row>
      <b-row>
        <!--Tpmk Trademark Owner--> 
          <b-col>
            <b-card :title=" $t('trademarkBulletin.bulletin_trademark_inventor_title') ">
              <b-list-group  v-for="(holder, index) in trademarkOwnerList">
                <b-list-group-item class="flex-column align-items-start">
                  <b-row>
                    <b-col>
                      <b-card-text :style="[tmpkTrademarkHolderList != null && holder.clientName != tmpkTrademarkHolderList[0].name ? { 'color': 'red' } :'']">
                            {{holder.ownerCode}}
                            {{holder.clientName}}
                          <br>
                      </b-card-text>
                      <b-card-text :style="[ tmpkTrademarkHolderList != null &&
                         holder.address != tmpkTrademarkHolderList[0].address ? { 'color': 'red' } :'']">
                            {{holder.address}}
                      </b-card-text>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
          <!--TPMK Trademark Inventor--> 
          <b-col>
            <b-card :title=" $t('trademarkBulletin.tpmk_bulletin_trademark_inventor_title') ">
              <b-list-group v-for="(holder, index) in tmpkTrademarkHolderList">
                <b-list-group-item class="flex-column align-items-start">
                  <b-row>
                    <b-col>
                      <b-card-text :style="[trademarkOwnerList != null && holder.name != trademarkOwnerList[0].clientName ? { 'color': 'red' } :'']">
                        {{ holder.tpeOwnerCode }}
                        {{ holder.name }}
                      </b-card-text>
                      <b-card-text :style="[trademarkOwnerList != null && holder.address != trademarkOwnerList[0].address ? { 'color': 'red' } :'']">
                        {{ holder.address }}
                      </b-card-text>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card :title="$t('trademarkBulletin.bulletin_priority_title')">
            <b-list-group-item>
              <!-- trademarkGoods'i farklarını v-html ile bas -->
              <b-card-text v-html="highlightedDiffTrademarkGoods">                
              </b-card-text>
            </b-list-group-item>
          </b-card>
        </b-col>
        <b-col>
          <b-card :title="$t('trademarkBulletin.tpmk_bulletin_priority_title')">
            <b-list-group-item>
              <!-- tpmkTrademarkGoods farklarını v-html ile bas -->
              <b-card-text v-html="highlightedDiffTpmkGoods"></b-card-text>
            </b-list-group-item>
          </b-card>
        </b-col>
      </b-row>
      </b-modal>
    </div>
  </template>
  <script>
  import { diffWords } from 'diff'
  export default {
    props: {
      getTrademarkBulletinCheckList: {type: Function},
    },
    computed: {
      highlightedDiffTrademarkGoods() {
        // Bunu trademarkGoods içindeki farkları renklendirir (diğer metinle kıyas)

        const diffResult = diffWords(this.trademarkGoods, this.tpmkTrademarkGoods)
        let html = ''

        diffResult.forEach(part => {
          if (part.removed) {
            // trademarkGoods'tan silinmiş kısımları kırmızı göster
            html += `<span style="color: red; text-decoration: underline;">${part.value}</span>`
          } else if (part.added != true) {
            // Diğer kısımlar normal
            html += `<span>${part.value}</span>`
          }
        })

        return html.replace(/\r\n/g, '<br>')
      },
      highlightedDiffTpmkGoods() {
        // Bu da tpmkTrademarkGoods içindeki farkları renklendirir (ilk metinle kıyas)
        const diffResult = diffWords(this.tpmkTrademarkGoods, this.trademarkGoods)
        let html = ''
        diffResult.forEach(part => {
          if (part.removed) {
            // tpmkTrademarkGoods'tan silinmiş kısımları kırmızı göster
            html += `<span style="color: red; text-decoration: underline;">${part.value}</span>`
          } else if (part.added != true) {
            html += `<span>${part.value}</span>`
          }
        })
        return html
      }
    },
    data() {
      return {
        id: 0,
        name: '',
        tpmkName: '',
        recordNo: '',
        employee:'',
        applicationNo: '',
        applicationDate: null,
        niceClasses: '',
        tpmkNiceClasses: '',
        bulletinNo: 0,
        bulletinDate: 0,
        tpmkApplicationNo: '',
        tpmkApplicationDate: null,
        tpmkBulletinNo: 0,
        tpmkBulletinDate: 0,
        tpmkAttorney: '',
        tmpkTrademarkHolderList: [],
        trademarkOwnerList: [],
        tpmkTrademarkGoods :'',
        trademarkGoods :'',
        no: 0,
        productName: '',
        productNameEn: '',
        locarnoCodes: '',
        tpmkNo: 0,
        tpmkProductName: '',
        tpmkProductNameEn: '',
        tpmkLocarnoCodes: '',
        searchTerm: '',
      }
    },
    methods: {
      normalizeText(str) {
        if (!str) return ''
        return str
          .replace(/\r\n/g, '\n')
          .replace(/\u00a0/g, ' ')
          .trim()
      },
      showInfo(id) {  
        this.$refs['trademarkBulletinCheckFormModal'].show()
        this.rows = []
        this.tpmkRows = []
        this.id = id
        const data = { id: this.id }
        const controller = { name: 'Bulletin', actionName: 'GetTrademarkBulletinInfoByTrademarkId' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
            if (response.data.resultStatus == true) {
                  //Trademark
                  this.name = response.data.trademark.name
                  this.applicationNo = response.data.trademark.applicationNo
                  this.applicationDate = response.data.trademark.applicationDate
                  this.trademarkGoods = response.data.trademarkNiceClass.goods
                  this.niceClasses = response.data.trademarkNiceClass.niceClass
                  this.trademarkOwnerList = response.data.trademarkOwnerList                  
                  this.bulletinNo = response.data.trademark.bulletinNo
                  this.bulletinDate = response.data.trademark.bulletinDate
                  this.recordNo = response.data.trademark.recordNo
                  this.employee = response.data.employee.fullName

                  this.tpmkName = response.data.tpmkTrademark.name
                  this.tpmkApplicationNo = response.data.tpmkTrademark.applicationNo
                  this.tpmkApplicationDate = response.data.tpmkTrademark.applicationDate
                  this.tpmkNiceClasses = response.data.tpmkTrademark.niceClasses
                  this.tpmkTrademarkGoods = response.data.tpmkTrademarkGoods.goods  
                  this.tpmkBulletinNo = response.data.tpmkTrademark.bulletinNo
                  this.tpmkBulletinDate = response.data.tpmkTrademark.bulletinDate
                  this.tpmkAttorney = response.data.tpmkTrademark.attorney
        
                  this.tmpkTrademarkHolderList = response.data.tmpkTrademarkHolderList
            }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  [dir] .dark-layout .card {
    background-color: #1c2333!important;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.24)!important;
}
.tableSize {
  font-size: 13px!important;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
}
  </style>
  