<template>
<div>
    <!-- modal -->
    <b-modal id="jobHistoryFormModal" centered size="lg" no-close-on-backdrop ref="jobHistoryFormModal" :title=" $t('jobHistory.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <label for="job-history-employee-input">{{ $t('jobHistory.employee') }}</label>
                    <b-form-select size="sm" v-model="employeeSelect" value-field="id" text-field="name" :options="employeeOption" />
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="job-history-job-status-path-input">{{ $t('jobHistory.job_status_path') }}</label>
                    <b-form-select size="sm" v-model="jobStatusPathSelect" value-field="id" text-field="name" :options="jobStatusPathOption" />
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="job-history-job-status-input">{{ $t('jobHistory.job_status') }}</label>
                    <b-form-select size="sm" v-model="jobStatusSelect" value-field="id" text-field="name" :options="jobStatusOption" />
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="job-history-date-input">{{ $t('jobHistory.date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="date"></CustomDatePicker>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="job-history-tpmk-start-date-input">{{ $t('jobHistory.tpmk_start_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="tpmkStartDate"></CustomDatePicker>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="job-history-tpmk-deadline-input">{{ $t('jobHistory.tpmk_deadline') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="tpmkDeadline"></CustomDatePicker>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="job-history-next-job-deadline-input">{{ $t('jobHistory.next_job_deadline') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="nextJobDeadline"></CustomDatePicker>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <label for="job-history-note-input">{{ $t('property.note') }}</label>
                    <b-form-textarea size="sm" id="job-history-note-input" v-model="note" rows="3" max-rows="8" maxlength="250" />
                </b-form>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

  
<script>
    export default {
        props: {
            getJobList: {
                type: Function
            },
            getJobHistoryList: {
                type: Function
            },
        },
        data() {
            return {
                id: 0,
                jobId: 0,
                jobStatusPathOption: [],
                jobStatusPathSelect: 0,
                jobStatusOption: [],
                jobStatusSelect: 0,
                employeeOption: [],
                employeeSelect: 0,
                date: null,
                tpmkStartDate: null,
                tpmkDeadline: null,
                nextJobDeadline: null,
                note: '',
            }
        },
        methods: {
            showInfo(id, jobId) {
                debugger;
                this.$refs['jobHistoryFormModal'].show()
                this.id = id
                this.jobId = jobId
                const data = {
                    id: this.id,
                    jobId: this.jobId
                }
                const controller = {
                    name: 'JobHistory'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.jobStatusPathOption = response.data.jobStatusPathList
                        this.jobStatusPathSelect = response.data.jobHistory.jobStatusPathId
                        if (response.data.jobStatusList !== null) {
                            this.jobStatusOption = response.data.jobStatusList

                        }
                        this.jobStatusSelect = response.data.jobHistory.jobStatusId
                        this.employeeOption = response.data.employeeList
                        this.employeeSelect = response.data.jobHistory.employeeId
                        this.date = response.data.jobHistory.date
                        this.tpmkStartDate = response.data.jobHistory.tpmkStartDate
                        this.tpmkDeadline = response.data.jobHistory.tpmkDeadline
                        this.nextJobDeadline = response.data.jobHistory.nextJobDeadline
                        this.note = response.data.jobHistory.note
                        if (this.id == 0) {
                            this.tpmkDeadline = response.data.formerJobHistoryTmpkDeadline
                        }
                    } else {
                        this.$bvModal.hide('jobHistoryFormModal')
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            },
            addOrUpdate() {
                const data = {
                    jobId: this.jobId,
                    id: this.id,
                    JobStatusRelationId: this.jobStatusPathSelect,
                    jobStatusId: this.jobStatusSelect,
                    date: this.date,
                    tpmkStartDate: this.tpmkStartDate,
                    employeeId: this.employeeSelect,
                    tpmkDeadline: this.tpmkDeadline,
                    nextJobDeadline: this.nextJobDeadline,
                    note: this.note
                }
                const controller = {
                    name: 'JobHistory'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.$bvModal.hide('jobHistoryFormModal')
                                this.getJobHistoryList()
                                this.$SaveAlert()
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                            }
                        })
                    }
                })
            },
        }
    }
</script>
  
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
