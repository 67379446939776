<template>
<div>
    <patent-form ref="patentFormModal"></patent-form>
    <b-modal id="agentTransactionsReportPatentModal" centered size="xl" no-close-on-backdrop ref="agentTransactionsReportPatentModal" :title=" $t('agentTransactionsReportPatent.title')" hide-footer>
        <!-- table -->
        <b-row>
          <b-col class="col-md-12 mb-1 custom-search d-flex justify-content-end">
                <div class="d-flex align-items-right">
                    <b-button style="float:right;"  size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                  </div>
            </b-col>
          </b-row>
        <vue-good-table id="agentTransactionsReportPatentTable" ref="agentTransactionsReportPatentTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :search-options="{
          enabled: true,
          externalQuery: searchTerm }" :pagination-options="{
          enabled: true,
          perPage:pageLength
        }">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>
            <!-- Row: Table Row -->
            <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'applicationNo'" class="text-nowrap">
                    <span>
                        {{ $t('agentTransactionsReportPatent.application_no') }} {{ $t('agentTransactionsReportPatent.application_date') }}
                    </span>
                </span>
            </template>
            <!-- Column: Table Column -->
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'recordNo'">
                    <b-row>
                        <b-col class="font-size: x-small">
                            <b-badge @click="showPatentInfoForm(props.row)" variant="primary" style="cursor: pointer;">
                                <feather-icon icon="MousePointerIcon" class="mr-25" />
                                <span>{{ props.row.recordNo }}</span>
                            </b-badge>
                        </b-col>
                    </b-row>
                </span>
                <span v-else-if="props.column.field === 'name'">
                    <b-row>
                        <b-col class="font-size: x-small">
                            <span>
                                {{ props.row.name }}
                            </span>
                        </b-col>
                    </b-row>
                </span>
                <span v-else-if="props.column.field === 'applicationNo'">
                    <b-row>
                        <b-col class="font-size: x-small">
                            <span>
                                Tr {{ props.row.applicationNo }} {{ $formatFn(props.row.applicationDate) }}
                                <br>
                                Ep {{ props.row.epApplicationNo }} {{ $formatFn(props.row.epApplicationDate) }}
                                <br>
                                Pct {{ props.row.pctApplicationNo }} {{ $formatFn(props.row.pctApplicationDate) }}
                            </span>
                        </b-col>
                    </b-row>
                </span>
                <span v-else-if="props.column.field === 'registerNo'">
                    <b-row>
                        <b-col class="font-size: x-small">
                            <span>
                                {{ props.row.registerNo }}
                                <br>
                                {{ $formatFn(props.row.registerDate) }}
                            </span>
                        </b-col>
                    </b-row>
                </span>
                <span v-else-if="props.column.field === 'ownerList'">
                    <b-col>
                        <b-row v-for="owner in props.row.ownerList" v-bind:key="owner.clientName" style="font-size: small;">
                            <tr v-if="props.row.ownerList.length != 1">
                                <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
                                <span>{{ owner.clientName }} </span> <br />
                            </tr>
                            <tr v-else-if="props.row.ownerList.length == 1">
                                <span>{{ owner.clientName }} </span> <br />
                            </tr>
                        </b-row>
                    </b-col>
                </span>
                <span v-else-if="props.column.field === 'patentStatus'">
                    <b-row>
                        <b-col class="font-size: x-small">
                            <span>
                                {{ props.row.patentStatus }}
                            </span>
                        </b-col>
                    </b-row>
                </span>
            </template>
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
                        </span>
                        <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                        <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </b-modal>
</div>
</template>
<script>
export default {
    data() {
        return {
            total: 0,
            pageLength: 50,
            dir: false,
            pageNo: 1,
            columns: [{
                    label: this.$t('agentTransactionsReportPatent.referance_no'),
                    field: 'recordNo',
                },
                {
                    label: this.$t('agentTransactionsReportTrademark.agent'),
                    field: 'agent',
                },
                {
                    label: this.$t('agentTransactionsReportPatent.owner'),
                    field: 'ownerList',
                },
                {
                    label: this.$t('agentTransactionsReportPatent.application_no'),
                    field: 'applicationNo',
                },
                {
                    label: this.$t('agentTransactionsReportPatent.agent_ref_no'),
                    field: 'agentReferenceNo',
                },
                {
                    label: this.$t('agentTransactionsReportPatent.name'),
                    field: 'title',
                },
                {
                    label: this.$t('agentTransactionsReportPatent.status'),
                    field: 'patentStatus',
                }
            ],
            rows: [],
            searchTerm: '',
            isAgent: false,
            isOwner: false,
            name: ''
        }
    },
    methods: {
        showPatentInfoForm(row) {
            this.$refs.patentFormModal.showInfo(row.id)
        },
        getAgentTransactionsReportList(recordType, clientAccountingId) {
            this.$refs.agentTransactionsReportPatentModal.show()
            this.rows = []
            const data = {
                recordType: recordType,
                clientAccountingId: clientAccountingId
            }
            const controller = {
                name: 'Client',
                actionName: 'AgentTransactionsReportDetail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            let loader = this.$loading.show()
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.rows = response.data.patentList
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
                loader.hide()
            })
        },
        getOwnerTransactionsReportList(recordType, clientId) {
            this.$refs.agentTransactionsReportPatentModal.show()
            this.rows = []
            const data = {
                recordType: recordType,
                clientId: clientId
            }
            const controller = {
                name: 'Client',
                actionName: 'AgentTransactionsReportDetail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            let loader = this.$loading.show()
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.rows = response.data.patentList
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
                loader.hide()
            })
        },
        exportExcel() {
            const controller = {
                name: 'Client',
                actionName: 'CanAgentExcelReport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Tüm İşlemler Raporu", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

<style>
.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
