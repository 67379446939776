<template>
<div>
    <design-opposition-form :getDesignOppositionList="getDesignOppositionList" ref="designOppositionFormModal"></design-opposition-form>
    <div class="custom-search">
        <b-row>
            <b-col class="col-md-2">
                <label for="design-opposition-record-no-input">{{ $t('designOpposition.record_no') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="recordNo" v-on:keyup.enter="getDesignOppositionList()" :placeholder="$t('designOpposition.record_no')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="design-opposition-name-input">{{ $t('designOpposition.design_name') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="designName" v-on:keyup.enter="getDesignOppositionList()" :placeholder="$t('designOpposition.design_name')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="design-opposition-agent-reference-no-input">{{ $t('designOpposition.agent_reference_no') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="agentReferenceNo" v-on:keyup.enter="getDesignOppositionList()" :placeholder="$t('designOpposition.agent_reference_no')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="design-opposition-owner-input">{{ $t('designOpposition.owner') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="owner" v-on:keyup.enter="getDesignOppositionList()" :placeholder="$t('designOpposition.owner')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-4 mt-2 custom-search d-flex justify-content-end">
                <div class="d-flex align-items-center">
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getDesignOppositionList()" @click="getDesignOppositionList()">{{ $t('others.list') }}</b-button>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('designOpposition.design_opposition_add') }}</b-button>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="clearSearchForm"> {{ $t('others.clear') }}</b-button>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-collapse class="mb-1 mt-1" accordion type="margin">
                    <app-collapse-item :title="$t('others.search')">
                        <b-row>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="design-opposition-status-input">{{ $t('designOpposition.status') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-select v-model="designOppositionStatusSelect" @change="getDesignOppositionList()" size="sm" value-field="id" text-field="name" :options="designOppositionStatusOption" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="design-opposition-basis-for-opposition-input">{{ $t('designOpposition.basis_for_opposition') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input size="sm" v-model="basisForOpposition" v-on:keyup.enter="getDesignOppositionList()" :placeholder="$t('designOpposition.basis_for_opposition')" type="text" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="design-opposition-agent-input">{{ $t('designOpposition.agent') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input size="sm" v-model="agent" v-on:keyup.enter="getDesignOppositionList()" :placeholder="$t('designOpposition.agent')" type="text" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="design-opposition-employee-input">{{ $t('designOpposition.employee') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input size="sm" v-model="employee" v-on:keyup.enter="getDesignOppositionList()" :placeholder="$t('designOpposition.employee')" type="text" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="design-opposition-bulletin-no-input">{{ $t('designOpposition.bulletin_no') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input size="sm" v-model="bulletinNo" v-on:keyup.enter="getDesignOppositionList()" :placeholder="$t('designOpposition.bulletin_no')" type="number" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="design-opposition-opposition-owner-input">{{ $t('designOpposition.opposition_owner') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input size="sm" v-model="oppositionOwner" v-on:keyup.enter="getDesignOppositionList()" :placeholder="$t('designOpposition.opposition_owner')" type="text" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="design-opposition-bulletin-date-start-input">{{ $t('designOpposition.bulletin_date_start') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="bulletinDateStart"></CustomDatePicker>                                
                            </b-col>
                            <b-col class="col-3">
                                <label for="design-opposition-bulletin-date-end-input">{{ $t('designOpposition.bulletin_date_end') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="bulletinDateEnd"></CustomDatePicker>                                
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <label for="design-opposition-record-date-start-input">{{ $t('designOpposition.record_date_start') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="recordDateStart"></CustomDatePicker>                                
                            </b-col>
                            <b-col class="col-3">
                                <label for="design-opposition-record-date-end-input">{{ $t('designOpposition.record_date_end') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="recordDateEnd"></CustomDatePicker>                                
                            </b-col>
                            <b-col class="col-3">
                                <label for="design-opposition-submit-answer-deadline-start-input">{{ $t('designOpposition.submit_answer_deadline_date_start') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="submitAnswerDeadlineDateStart"></CustomDatePicker>                                
                            </b-col>
                            <b-col class="col-3">
                                <label for="design-opposition-submit-answer-deadline-date-end-input">{{ $t('designOpposition.submit_answer_deadline_date_end') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="submitAnswerDeadlineDateEnd"></CustomDatePicker>                                
                            </b-col>
                        </b-row>
                        <b-col class="col-3">
                                <label for="design-opposition-application-no-input">{{ $t('designOpposition.application_no') }}</label>
                                <b-form-input size="sm" v-model="applicationNo" v-on:keyup.enter="getDesignOppositionList()" :placeholder="$t('designOpposition.application_no')" type="text" class="d-inline-block" />
                            </b-col>
                        <b-row>
                        </b-row>
                    </app-collapse-item>
                </app-collapse> 
            </b-col>
        </b-row>
    </div>   
    <!-- table -->
    <vue-good-table id="designOppositionTable" ref="designOppositionTable" :columns="columns" :rows="rows" :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }" styleClass="vgt-table condensed tableSize" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }" :pagination-options="{
            enabled: true,
            perPage: pageLength
          }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'recordNo'" class="text-nowrap">
                {{ $t('designOpposition.record_no') }}
            </span>
            <span v-else-if="props.column.field === 'designName'" class="text-nowrap">
                {{ $t('designOpposition.design_name') }}
            </span>
            <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
                {{ $t('designOpposition.agent_name') }}
            </span>
            <span v-else-if="props.column.field === 'owner'" class="text-nowrap">
                {{ $t('designOpposition.holder_name') }}
            </span>
            <span v-else-if="props.column.field === 'applicationNo'" class="text-nowrap">
                {{ $t('designOpposition.application_no') }}
            </span>
            <span v-else-if="props.column.field === 'designOppositionBasisForOppositionList'" class="text-nowrap">
                {{ $t('designOpposition.basis_for_opposition') }}
            </span>
            <span v-else-if="props.column.field === 'oppositionDate'" class="text-nowrap">
                {{ $t('designOpposition.opposition_date') }}
            </span>
            <span v-else-if="props.column.field === 'status'" class="text-nowrap">
                {{ $t('designOpposition.status') }}
            </span>
            <span v-else-if="props.column.field === 'Note'" class="text-nowrap">
                {{ $t('designOpposition.note') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Agent Name -->
            <span v-if="props.column.field === 'agent'" class="text-nowrap">
                <b-row>
                    <b-col class="font-size: x-small">
                        <span>{{ props.row.agent }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small">{{ props.row.clientAccounting }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'designOppositionBasisForOppositionList'">
                <b-col>
                    <b-row v-for="designOppositionBasisForOpposition in props.row.designOppositionBasisForOppositionList" v-bind:data="designOppositionBasisForOpposition" v-bind:key="designOppositionBasisForOpposition.name" style="font-size: x-small;">
                        <tr>
                            <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
                            <span>{{ designOppositionBasisForOpposition.name }} </span> <br />
                        </tr>
                    </b-row>
                </b-col>
            </span>
            <!-- Column: Registration Date Format -->
            <span v-else-if="props.column.field === 'Record Date'" class="text-nowrap">
                <span>{{ props.row.recordDate }}</span>
            </span>
            <span v-else-if="props.column.field === 'Appeal Deadline'" class="text-nowrap">
                <span>{{ props.row.apperialDeadline }}</span>
            </span>
            <!-- Column: Process -->
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">

                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getDesignOppositionList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>    
export default {
    watch: {
        bulletinNo(newValue) {
            if(newValue == ''){
                this.bulletinNo = null
            }
        },
    },
    data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            columns: [{
                    label: this.$t('designOpposition.record_no'),
                    field: `recordNo`,
                },
                {
                    label: this.$t('designOpposition.design_name'),
                    field: `designName`,
                },
                {
                    label: this.$t('designOpposition.agent_name'),
                    field: `agent`,
                },
                {
                    label: this.$t('designOpposition.holder_name'),
                    field: `owner`,
                },
                {
                    label: this.$t('designOpposition.application_no'),
                    field: `applicationNo`,
                },
                {
                    label: this.$t('designOpposition.basis_for_opposition'),
                    field: 'designOppositionBasisForOppositionList'
                },
                {
                    label: this.$t('designOpposition.oppositionDate'),
                    field: `oppositionDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('designOpposition.status'),
                    field: `status`
                },
                // {
                //   label: 'Trademark',
                //   field: `tradermakName`,
                // },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },

            ],
            rows: [],
            searchTerm: '',
            designName: '',
            applicationNo: '',
            owner: '',
            bulletinNo: null,
            bulletinDateStart: null,
            bulletinDateEnd: null,
            recordDateStart: null,
            recordDateEnd: null,
            submitAnswerDeadlineDateStart : null,
            submitAnswerDeadlineDateEnd : null,
            recordNo: '',
            agentReferenceNo: '',
            agent: '',
            employee: '',
            basisForOpposition: '',
            oppositionOwner : '',
            designOppositionStatusSelect: 0,
            designOppositionStatusOption: [],
            total: 0,
            pageLength: 10,
            pageNo: 1,
            number: 0,
        }
    },
    mounted() {
        this.getDesignOppositionList()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'DesignOpposition'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getDesignOppositionList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getDesignOppositionList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.designOppositionFormModal.showInfo(id)
        },
        getDesignOppositionList() {
            this.rows = []
            const data = {
                designName: this.designName,
                applicationNo: this.applicationNo,
                owner: this.owner,
                bulletinNo: this.bulletinNo,
                bulletinDateStart: this.bulletinDateStart,
                bulletinDateEnd: this.bulletinDateEnd,
                recordNo: this.recordNo,
                agentReferenceNo: this.agentReferenceNo,
                agent: this.agent,
                employee: this.employee,
                basisForOpposition: this.basisForOpposition,
                recordDateStart: this.recordDateStart,
                recordDateEnd: this.recordDateEnd,
                designOpppositionStatusId: this.designOppositionStatusSelect,
                oppositionOwner : this.oppositionOwner,
                submitAnswerDeadlineDateEnd : this.submitAnswerDeadlineDateEnd,
                submitAnswerDeadlineDateStart : this.submitAnswerDeadlineDateStart,
                pageLength: this.pageLength,
                pageNo: this.pageNo
            }
            const controller = {
                name: 'DesignOpposition'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.designOppositionList.forEach(element => {
                        this.rows.push(element)
                    })
                    this.total = response.data.total
                    this.designOppositionStatusOption = response.data.designOppositionStatusList
                    this.designOppositionStatusOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            var data = {
                designName: this.designName,
                applicationNo: this.applicationNo,
                owner: this.owner,
                bulletinNo: this.bulletinNo,
                bulletinDateStart: this.bulletinDateStart,
                bulletinDateEnd: this.bulletinDateEnd,
                recordNo: this.recordNo,
                agentReferenceNo: this.agentReferenceNo,
                agent: this.agent,
                employee: this.employee,
                basisForOpposition: this.basisForOpposition,
                recordDateStart: this.recordDateStart,
                recordDateEnd: this.recordDateEnd,
                designOpppositionStatusId: this.designOppositionStatusSelect,
                oppositionOwner : this.oppositionOwner,
                pageLength: 1000,
                pageNo: 1
            }
            var controller = {
                name: 'DesignOpposition'
            }
            var payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {                    
                    controller = {
                        name: 'DesignOpposition',
                        actionName: 'CanExport'
                    }
                    data = {
                        columnList: this.columns,
                        DataList: response.data.designOppositionList
                    }
                    payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$downloadFile("Tasarimİtirazlari", response.data.base64, 'xlsx')
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })

                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clearSearchForm() {
            this.recordNo = '';
            this.designName = '';
            this.agent = '';
            this.owner = '';
            this.employee = '';
            this.basisForOpposition = '';
            this.applicationNo = '',
            this.bulletinNo = null,
            this.bulletinDateStart = null,
            this.bulletinDateEnd = null,
            this.recordDateStart = null,
            this.recordDateEnd = null,
            this.submitAnswerDeadlineDateStart = null,
            this.submitAnswerDeadlineDateEnd = null,
            this.agentReferenceNo = '',
            this.oppositionOwner = '',
            this.designOppositionStatusSelect = 0,
            this.number = 0,

            // Tabloyu yenile
            this.getDesignOppositionList();
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

[dir] .dark-layout .card {
    background-color: #252d3f !important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
}

.akista-d-flex {
    display: contents !important;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
