<template>
<div>
    <design-form :getDesignList="getDesignList" ref="designFormModal"></design-form>
    <div class="custom-search">
        <b-row>
            <b-col class="col-md-2">
                <label for="design-record-no-input">{{ $t('design.record_no') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="recordNo" v-on:keyup.enter="getDesignList()" :placeholder="$t('design.record_no')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="design-agent-input">{{ $t('design.agent') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="agent" v-on:keyup.enter="getDesignList()" :placeholder="$t('design.agent')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="design-register-no-input">{{ $t('design.register_no') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="registerNo" v-on:keyup.enter="getDesignList()" :placeholder="$t('design.register_no')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="design-record-no-input">{{ $t('design.application_no') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="applicationNo" v-on:keyup.enter="getDesignList()" :placeholder="$t('design.application_no')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-4 mt-2 custom-search d-flex justify-content-end">
                <div class="d-flex align-items-center">
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getDesignList()" @click="getDesignList()">{{ $t('others.list') }}</b-button>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('design.design_add') }}</b-button>
                    <b-dropdown style="width: 7em; height: 2.1em;" id="dropdown-grouped" variant="outline-primary" right size="sm" :text="$t('design.process')"></b-dropdown>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="clearSearchForm"> {{ $t('others.clear') }}</b-button>

                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-collapse class="mb-1 mt-1" accordion type="margin">
                    <app-collapse-item :title="$t('others.search')">
                        <b-row>
                            <b-col class="col-3">
                                <label for="design-owner-input">{{ $t('design.owner') }}</label>
                                <div class="d-flex align-items-center">
                                    <b-form-input size="sm" v-model="owner" v-on:keyup.enter="getDesignList()" :placeholder="$t('design.owner')" type="text" class="d-inline-block" />
                                </div>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="design-status-input">{{ $t('design.status') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-select v-model="designStatusSelect" @change="getDesignList()" size="sm" value-field="id" text-field="name" :options="designStatusOption" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="design-bulletin-no-input">{{ $t('design.bulletin_no') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input size="sm" v-model="bulletinNo" v-on:keyup.enter="getDesignList()" :placeholder="$t('design.bulletin_no')" type="text" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="design-delayed-publish-no-input">{{ $t('design.delayed_publish_no') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input size="sm" v-model="delayedPublishNo" v-on:keyup.enter="getDesignList()" :placeholder="$t('design.delayed_publish_no')" type="text" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="design-agent-reference-no-input">{{ $t('design.agent_reference_no') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input size="sm" v-model="agentReferenceNo" v-on:keyup.enter="getDesignList()" :placeholder="$t('design.agent_reference_no')" type="text" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="design-priority-filling-date-start-input">{{ $t('design.priority_filling_date_start') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="priorityFillingDateStart"></CustomDatePicker>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <label for="design-priority-filling-date-end-input">{{ $t('design.priority_filling_date_end') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="priorityFillingDateEnd"></CustomDatePicker>
                            </b-col>
                            <b-col class="col-3">
                                <label for="design-record-date-start-input">{{ $t('design.record_date_start') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="recordDateStart"></CustomDatePicker>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <label for="design-record-date-end-input">{{ $t('design.record_date_end') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="recordDateEnd"></CustomDatePicker>
                            </b-col>
                            <b-col class="col-3">
                                <label for="design-application-date-start-input">{{ $t('design.application_date_start') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="applicationDateStart"></CustomDatePicker>
                            </b-col>
                            <b-col class="col-3">
                                <label for="design-application-date-end-input">{{ $t('design.application_date_end') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="applicationDateEnd"></CustomDatePicker>
                            </b-col>
                            <b-col class="col-3">
                                <label for="design-bulletin-date-start-input">{{ $t('design.bulletin_date_start') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="bulletinDateStart"></CustomDatePicker>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3 mt-1">
                                <label for="design-bulletin-date-end-input">{{ $t('design.bulletin_date_end') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="bulletinDateEnd"></CustomDatePicker>
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="design-delayed-publish-date-start-input">{{ $t('design.delayed_publish_date_start') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="delayedPublishDateStart"></CustomDatePicker>
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="design-delayed-publish-date-end-input">{{ $t('design.delayed_publish_date_end') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="delayedPublishDateEnd"></CustomDatePicker>
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="design-instruction-date-start-input">{{ $t('design.instruction_date_start') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="instructionDateStart"></CustomDatePicker>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3 mt-1">
                                <label for="design-instruction-date-end-input">{{ $t('design.instruction_date_end') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="instructionDateEnd"></CustomDatePicker>
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="design-register-date-start-input">{{ $t('design.register_date_start') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="registerDateStart"></CustomDatePicker>
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="design-register-date-end-input">{{ $t('design.register_date_end') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="registerDateEnd"></CustomDatePicker>
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="design-requested-publish-date-start-input">{{ $t('design.requested_publish_date_start') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="requestedPublishDateStart"></CustomDatePicker>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3 mt-1">
                                <label for="design-renewal--reminder-date-start-input">{{ $t('design.renewal_reminder_date_start') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="renewalReminderDateStart"></CustomDatePicker>
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="design-renewal-reminder-date-end-input">{{ $t('design.renewal_reminder_date_end') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="renewalReminderDateEnd"></CustomDatePicker>
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <b-form-group>
                                    <label for="design-document-no-input">{{ $t('design.document_no') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input size="sm" v-model="documentNo" v-on:keyup.enter="getDesignList()" :placeholder="$t('design.document_no')" type="text" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="design-requested-publish-date-end-input">{{ $t('design.requested_publish_date_end') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="requestedPublishDateEnd"></CustomDatePicker>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3 mt-1">
                                <label for="design-note-input">{{ $t('design.note') }}</label>
                                <div class="d-flex align-items-center">
                                    <b-form-input size="sm" v-model="note" v-on:keyup.enter="getDesignList()" :placeholder="$t('design.note')" type="text" class="d-inline-block" />
                                </div>
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="design-maximum-postponement-date-start-input">{{ $t('design.maximum_postponement_date_start') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="maximumPostponementDateStart"></CustomDatePicker>
                            </b-col>
                            <b-col class="col-3 mt-1">
                                <label for="design-maximum-postponement-date-end-input">{{ $t('design.maximum_postponement_date_end') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="maximumPostponementDateEnd"></CustomDatePicker>
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse>
            </b-col>
        </b-row>
    </div>
    <!-- table -->
    <vue-good-table id="designTable" ref="designTable" :columns="columns" :rows="rows" :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }" styleClass="vgt-table condensed tableSizeDesign" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }" :pagination-options="{
            enabled: true,
            perPage: pageLength
          }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'recordNo'" class="text-nowrap">
                {{ $t('design.record_no') }}
            </span>
            <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
                {{ $t('design.agent_name') }}
            </span>
            <span v-else-if="props.column.field === 'ownerList'" class="text-nowrap">
                {{ $t('design.owners') }}
            </span>
            <span v-else-if="props.column.field === 'designStatus'" class="text-nowrap">
                {{ $t('design.status') }}
            </span>
            <span v-else-if="props.column.field === 'desingDetailCount'" class="text-nowrap">
                {{ $t('design.detail_count') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Agent Name -->
            <span v-if="props.column.field === 'agent'">
                <b-row>
                    <b-col class="font-size: x-small">
                        <span>{{ props.row.agent }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small">{{ props.row.clientAccounting }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'designStatus'">
                <span>{{ props.row.designStatus }}</span>
            </span>
            <span v-else-if="props.column.field === 'ownerList'">
                <b-col>
                    <b-row v-for="owner in props.row.ownerList" v-bind:key="owner.clientName" style="font-size: small;">
                        <tr v-if="props.row.ownerList.length != 1">
                            <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
                            <span>{{ owner.clientName }} </span> <br />
                        </tr>
                        <tr v-else-if="props.row.ownerList.length == 1">
                            <span>{{ owner.clientName }} </span> <br />
                        </tr>
                    </b-row>
                </b-col>
            </span>
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">

                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getDesignList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
    export default {
        data() {
            return {
                total: 0,
                pageLength: 10,
                dir: false,
                pageNo: 1,
                columns: [{
                        label: this.$t('design.record_no'),
                        field: `recordNo`,
                    },
                    {
                        label: this.$t('design.agent_name'),
                        field: `agent`,
                    },
                    {
                        label: this.$t('design.owners'),
                        field: `ownerList`,
                    },
                    {
                        label: this.$t('design.status'),
                        field: `designStatus`,
                    },
                    {
                        label: this.$t('design.detail_count'),
                        field: 'desingDetailCount',
                    },
                    {
                        label: 'Process',
                        field: 'process',
                        width: '10em'
                    },
                ],
                rows: [],
                designStatusOption: [],
                designStatusSelect: 0,
                searchTerm: '',
                agent: '',
                recordNo: '',
                agentReferenceNo: '',
                owner: '',
                registerNo: '',
                recordDateStart: null,
                recordDateEnd: null,
                applicationDateStart: null,
                applicationDateEnd: null,
                bulletinDateStart: null,
                bulletinDateEnd: null,
                bulletinNo: null,
                delayedPublishNo: null,
                delayedPublishDateStart: null,
                delayedPublishDateEnd: null,
                documentNo: '',
                instructionDateStart: null,
                instructionDateEnd: null,
                lastApplicationDateStart: null,
                lastApplicationDateEnd: null,
                registerDateStart: null,
                registerDateEnd: null,
                priorityFillingDateStart: null,
                priorityFillingDateEnd: null,
                requestedPublishDateStart: null,
                requestedPublishDateEnd: null,
                renewalReminderDateStart: null,
                renewalReminderDateEnd: null,
                maximumPostponementDateEnd : null,
                maximumPostponementDateStart : null,
                note: '',
                applicationNo: '',
            }
        },
        mounted() {
            this.getDesignList()
        },
        methods: {
            async deleted(data) {
                data = {
                    id: data.id
                }
                const controller = {
                    name: 'Design'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$DeleteConfirm().then(result => {
                    if (result.value) {
                        this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.$DeleteAlert()
                                this.getDesignList()
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                                this.getDesignList()
                            }
                        })
                    }
                })
            },
            async getInfo(id) {
                this.$refs.designFormModal.showInfo(id)
            },
            getDesignList() {
                this.rows = []
                const controller = {
                    name: 'Design'
                }
                const data = {
                    agentReferenceNo: this.agentReferenceNo,
                    designStatusId: this.designStatusSelect,
                    recordNo: this.recordNo,
                    agent: this.agent,
                    recordDateStart: this.recordDateStart,
                    recordDateEnd: this.recordDateEnd,
                    owner: this.owner,
                    registerNo: this.registerNo,
                    applicationDateStart: this.applicationDateStart,
                    applicationDateEnd: this.applicationDateEnd,
                    bulletinDateStart: this.bulletinDateStart,
                    bulletinDateEnd: this.bulletinDateEnd,
                    bulletinNo: this.bulletinNo,
                    delayedPublishNo: this.delayedPublishNo,
                    delayedPublishDateStart: this.delayedPublishDateStart,
                    delayedPublishDateEnd: this.delayedPublishDateEnd,
                    documentNo: this.documentNo,
                    instructionDateStart: this.instructionDateStart,
                    instructionDateEnd: this.instructionDateEnd,
                    lastApplicationDateStart: this.lastApplicationDateStart,
                    lastApplicationDateEnd: this.lastApplicationDateEnd,
                    registerDateStart: this.registerDateStart,
                    registerDateEnd: this.registerDateEnd,
                    priorityFillingDateStart: this.priorityFillingDateStart,
                    priorityFillingDateEnd: this.priorityFillingDateEnd,
                    requestedPublishDateStart: this.requestedPublishDateStart,
                    requestedPublishDateEnd: this.requestedPublishDateEnd,
                    renewalReminderDateStart: this.renewalReminderDateStart,
                    renewalReminderDateEnd: this.renewalReminderDateEnd,
                    maximumPostponementDateStart : this.maximumPostponementDateStart,
                    maximumPostponementDateEnd : this.maximumPostponementDateEnd,
                    note: this.note,
                    pageLength: this.pageLength,
                    pageNo: this.pageNo,
                    applicationNo: this.applicationNo

                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.designStatusSelect = this.designStatusSelect
                        this.designStatusOption = response.data.designStatusList
                        this.designStatusOption.splice(0, 0, this.$nullSelected())
                        this.total = response.data.total
                        response.data.designList.forEach(element => {
                            this.rows.push(element)
                        })
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            },
            exportExcel() {
                var controller = {
                    name: 'Design'
                }
                var data = {
                    agentReferenceNo: this.agentReferenceNo,
                    designStatusId: this.designStatusSelect,
                    recordNo: this.recordNo,
                    agent: this.agent,
                    recordDateStart: this.recordDateStart,
                    recordDateEnd: this.recordDateEnd,
                    owner: this.owner,
                    registerNo: this.registerNo,
                    applicationDateStart: this.applicationDateStart,
                    applicationDateEnd: this.applicationDateEnd,
                    bulletinDateStart: this.bulletinDateStart,
                    bulletinDateEnd: this.bulletinDateEnd,
                    bulletinNo: this.bulletinNo,
                    delayedPublishNo: this.delayedPublishNo,
                    delayedPublishDateStart: this.delayedPublishDateStart,
                    delayedPublishDateEnd: this.delayedPublishDateEnd,
                    documentNo: this.documentNo,
                    instructionDateStart: this.instructionDateStart,
                    instructionDateEnd: this.instructionDateEnd,
                    lastApplicationDateStart: this.lastApplicationDateStart,
                    lastApplicationDateEnd: this.lastApplicationDateEnd,
                    registerDateStart: this.registerDateStart,
                    registerDateEnd: this.registerDateEnd,
                    priorityFillingDateStart: this.priorityFillingDateStart,
                    priorityFillingDateEnd: this.priorityFillingDateEnd,
                    requestedPublishDateStart: this.requestedPublishDateStart,
                    requestedPublishDateEnd: this.requestedPublishDateEnd,
                    renewalReminderDateStart: this.renewalReminderDateStart,
                    renewalReminderDateEnd: this.renewalReminderDateEnd,
                    note: this.note,
                    pageLength: 10000,
                    pageNo: 1,

                }
                var payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                    if (response.data.resultStatus == true) {

                        controller = {
                            name: 'Design',
                            actionName: 'CanExport'
                        }
                        data = {
                            columnList: this.columns,
                            DataList: response.data.designList
                        }
                        payload = {
                            data: data,
                            controller: controller
                        }
                        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.$downloadFile("Tasarim", response.data.base64, 'xlsx')
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                            }
                        })
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })

            },
            clearSearchForm() {
                this.recordNo = '';
                this.designName = '';
                this.agent = '';
                this.owner = '';
                this.applicationNo = '';
                this.registerNo = '';
                this.designStatusSelect = 0;
                this.applicationDateStart = null;
                this.applicationDateEnd = null;
                this.registerDateStart = null;
                this.registerDateEnd = null;
                this.priorityDateStart = null;
                this.priorityDateEnd = null;
                this.bulletinNo =  null,
                this.delayedPublishNo =  null,
                this.agentReferenceNo = '',
                this.recordDateStart = null,
                this.recordDateEnd = null,
                this.bulletinDateStart = null,
                this.bulletinDateEnd = null,
                this.delayedPublishDateStart = null,
                this.delayedPublishDateEnd = null,
                this.documentNo = '',
                this.instructionDateStart = null,
                this.instructionDateEnd = null,
                this.lastApplicationDateStart = null,
                this.lastApplicationDateEnd = null,
                this.priorityFillingDateStart = null,
                this.priorityFillingDateEnd = null,
                this.requestedPublishDateStart = null,
                this.requestedPublishDateEnd = null,
                this.renewalReminderDateStart = null,
                this.renewalReminderDateEnd = null,
                this.maximumPostponementDateEnd = null,
                this.maximumPostponementDateStart = null,
                this.note = '',

                // Tabloyu yenile
                this.getDesignList();
            }
        },
    }
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

[dir] .dark-layout .card {
    background-color: #252d3f !important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
}

.akista-d-flex {
    display: contents !important;
}

/* Solid border */
hr.solid {
    border-top: 3px solid #bbb;
}

.tableSizeDesign {
    font-size: 14px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.featherIconReSize {
    width: 12px;
    height: 12px;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
