<template>
<div>
    <official-fee-form :getOfficialFeeList="getOfficialFeeList" ref="officialFeeFormModal"></official-fee-form>
    <div class="custom-search">
        <b-row>
            <b-col class="col-md-2">
                <label for="official-fee-recordNo-input">{{ $t('officialFee.record_no') }}</label>
                <b-form>
                    <b-form-input @input="getOfficialFeeList()" :placeholder="$t('officialFee.record_no')" size="sm" id="official-fee-recordNo-input" v-model="recordNo" maxlength="12" />
                </b-form>
            </b-col>
            <b-col class="col-md-2">
                <label for="official-fee-receiptNo-input">{{ $t('officialFee.receipt_no') }}</label>
                <b-form>
                    <b-form-input @input="getOfficialFeeList()" :placeholder="$t('officialFee.receipt_no')" size="sm" id="official-fee-receiptNo-input" v-model="receiptNo" maxlength="5," />
                </b-form>
            </b-col>
            <b-col class="col-md-2">
                <b-form>
                    <label for="official-fee-employee-input">{{ $t('officialFee.employee') }}</label>
                    <b-form-select id="official-fee-employee-input" size="sm" v-model="employeeSelect" value-field="id" text-field="name" :options="employeeOption" @input="getOfficialFeeList()" />
                </b-form>
            </b-col>
            <b-col class="col-md-2">
                <label for="official-fee-name-input">{{ $t('officialFee.official_fee_name') }}</label>
                <b-form>
                    <b-form-input @input="getOfficialFeeList()" :placeholder="$t('officialFee.official_fee_name')" size="sm" id="official-fee-name-input" v-model="name" maxlength="500" />
                </b-form>
            </b-col>
            <b-col class="col-md-4 mt-2 custom-search d-flex justify-content-end">
                <div class="d-flex align-items-center">
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getOfficialFeeList()" @click="getOfficialFeeList()">{{ $t('officialFee.list') }}</b-button>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('officialFee.add') }}</b-button>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="clearSearchForm"> {{ $t('others.clear') }}</b-button>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-collapse class="mb-1" accordion type="margin">
                    <app-collapse-item :title="$t('others.search')">
                        <b-row>
                            <b-col class="col-md-2">
                                <b-form>
                                    <label for="official-fee-module-input">{{ $t('officialFee.module') }}</label>
                                    <b-form-select id="official-fee-module-input" size="sm" v-model="moduleSelect" value-field="id" text-field="name" :options="moduleOption" @input="getOfficialFeeList()" />
                                </b-form>
                            </b-col>
                            <b-col class="col-md-2">
                                <b-form>
                                    <label for="official-fee-type-input">{{ $t('officialFee.official_fee_type') }}</label>
                                    <b-form-select id="official-fee-type-input" size="sm" v-model="officialFeeTypeSelect" value-field="id" text-field="name" :options="officialFeeTypeOption" @input="getOfficialFeeList()" />
                                </b-form>
                            </b-col>
                            <b-col class="col-md-2">
                                <b-form>
                                    <label for="official-fee-refund-status-input">{{ $t('officialFee.official_fee_refund_status') }}</label>
                                    <b-form-select id="official-fee-refund-status-input" size="sm" v-model="officialFeeRefundStatusSelect" value-field="id" text-field="name" :options="officialFeeRefundStatusOption" @input="getOfficialFeeList()" />
                                </b-form>
                            </b-col>
                            <b-col class="col-md-2">
                                <b-form>
                                    <label for="official-fee-owner-type-input">{{ $t('officialFee.official_fee_owner_type') }}</label>
                                    <b-form-select id="official-fee-owner-type-input" size="sm" v-model="officialFeeOwnerTypeSelect" value-field="id" text-field="name" :options="officialFeeOwnerTypeOption" @input="getOfficialFeeList()" />
                                </b-form>
                            </b-col>
                            <b-col class="col-md-2">
                                <b-form>
                                    <label for="official-fee-credit-card-input">{{ $t('officialFee.credit_card') }}</label>
                                    <b-form-select id="official-fee-credit-card-input" size="sm" v-model="creditCardSelect" value-field="id" text-field="name" :options="creditCardOption" @input="getOfficialFeeList()" />
                                </b-form>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-md-2">
                                <label for="official-fee-document_no-input">{{ $t('officialFee.document_no') }}</label>
                                <b-form>
                                    <b-form-input @input="getOfficialFeeList()" :placeholder="$t('officialFee.document_no')" size="sm" id="official-fee-document-no-input" v-model="documentNo" maxlength="20" />
                                </b-form>
                            </b-col>
                            <b-col class="col-md-2">
                                <b-form>
                                    <label for="official-fee-document-date-start-input">{{ $t('officialFee.document_date_start') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="documentDateStart"></CustomDatePicker>
                                </b-form>
                            </b-col>
                            <b-col class="col-md-2">
                                <b-form>
                                    <label for="official-fee-document-date-end-input">{{ $t('officialFee.document_date_end') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="documentDateEnd"></CustomDatePicker>
                                </b-form>
                            </b-col>
                            <b-col class="col-md-2">
                                <b-form>
                                    <label for="official-fee-receipt-date-start-input">{{ $t('officialFee.receipt_date_start') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="receiptDateStart"></CustomDatePicker>
                                </b-form>
                            </b-col>
                            <b-col class="col-md-2">
                                <b-form>
                                    <label for="official-fee-receipt-date-end-input">{{ $t('officialFee.receipt_date_end') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="receiptDateEnd"></CustomDatePicker>
                                </b-form>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-md-2">
                                <label for="official-fee-price-start-input">{{ $t('officialFee.price_start') }}</label>
                                <b-form>
                                    <b-form-input @input="getOfficialFeeList()" :placeholder="$t('officialFee.price_start')" size="sm" id="official-fee-price-start-input" v-model="priceStart" />
                                </b-form>
                            </b-col>
                            <b-col class="col-md-2">
                                <label for="official-fee-price-end-input">{{ $t('officialFee.price_end') }}</label>
                                <b-form>
                                    <b-form-input @input="getOfficialFeeList()" :placeholder="$t('officialFee.price_end')" size="sm" id="official-fee-price-end-input" v-model="priceEnd" />
                                </b-form>
                            </b-col>
                            <b-col class="col-md-2">
                                <b-form>
                                    <label for="official-fee-currency-input">{{ $t('officialFee.currency') }}</label>
                                    <b-form-select id="official-fee-currency-input" size="sm" v-model="currencySelect" value-field="id" text-field="code" :options="currencyOption" @input="getOfficialFeeList()" />
                                </b-form>
                            </b-col>
                            <b-col class="col-md-2">
                                <label for="official-fee-client-accounting-input">{{ $t('officialFee.client_accounting') }}</label>
                                <b-form>
                                    <b-form-input @input="getOfficialFeeList()" :placeholder="$t('officialFee.client_accounting')" size="sm" id="official-fee-client-accounting-input" v-model="clientAccounting" />
                                </b-form>
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse>
            </b-col>
        </b-row>
    </div>
    <!-- table -->
    <vue-good-table id="officialFeeTable" ref="officialFeeTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" :pagination-options="{
        enabled: true,
        perPage: pageLength
      }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'receiptDate'" class="text-nowrap">
                {{ $t('officialFee.receipt_date') }}
            </span>
            <span v-else-if="props.column.field === 'date'" class="text-nowrap">
                {{ $t('officialFee.date') }}
            </span>
            <span v-else-if="props.column.field === 'officialFeeType'" class="text-nowrap">
                {{ $t('officialFee.official_fee_type') }}
            </span>
            <span v-else-if="props.column.field === 'officialFeeOwnerType'" class="text-nowrap">
                {{ $t('officialFee.official_fee_owner_type') }}
            </span>
            <span v-else-if="props.column.field === 'recordNo'" class="text-nowrap">
                {{ $t('officialFee.record_no') }}
            </span>
            <span v-else-if="props.column.field === 'applicationNo'" class="text-nowrap">
                {{ $t('officialFee.application_no') }}
            </span>
            <span v-else-if="props.column.field === 'employee'" class="text-nowrap">
                {{ $t('officialFee.name') }}
            </span>
            <span v-else-if="props.column.field === 'receiptNo'" class="text-nowrap">
                {{ $t('officialFee.receipt_no') }}
            </span>
            <span v-else-if="props.column.field === 'price'" class="text-nowrap">
                {{ $t('officialFee.price') }}
            </span>
            <span v-else-if="props.column.field === 'officialFeeValue'" class="text-nowrap">
                {{ $t('officialFee.official_fee') }}
            </span>
            <span v-else-if="props.column.field === 'currency'" class="text-nowrap">
                {{ $t('officialFee.currency') }}
            </span>
            <span v-else-if="props.column.field === 'invoiceDate'" class="text-nowrap">
                {{ $t('officialFee.invoice_date') }}
            </span>
            <span v-else-if="props.column.field === 'invoiceNo'" class="text-nowrap">
                {{ $t('officialFee.invoice_no') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">

                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <!-- <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1"
                size="sm" @click="deleted(props.row)">
                <feather-icon icon="XIcon" />
              </b-button> -->
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}
                    </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getOfficialFeeList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            pageNo: 1,
            columns: [{
                    label: this.$t('officialFee.receipt_date'),
                    field: `receiptDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('officialFee.date'),
                    field: `date`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('officialFee.official_fee_type'),
                    field: `officialFeeType`,
                },
                {
                    label: this.$t('officialFee.official_fee_owner_type'),
                    field: `officialFeeOwnerType`,
                },
                {
                    label: this.$t('officialFee.record_no'),
                    field: `recordNo`,
                },
                {
                    label: this.$t('officialFee.name'),
                    field: `employee`,
                },
                {
                    label: this.$t('officialFee.receipt_no'),
                    field: `receiptNo`,
                },
                {
                    label: this.$t('officialFee.price'),
                    field: `price`,
                    formatFn: this.$formatMoney,
                },
                {
                    label: this.$t('officialFee.official_fee'),
                    field: `officialFeeValue`,
                    formatFn: this.$formatMoney,
                },
                {
                    label: this.$t('officialFee.currency'),
                    field: `currency`,
                },
                {
                    label: this.$t('officialFee.invoice_date'),
                    field: `invoiceDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('officialFee.invoice_no'),
                    field: `invoiceNo`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '7em'
                },
            ],
            rows: [],
            moduleId: 0,
            creditCardId: 0,
            documentNo: '',
            clientAccounting: '',
            employee: '',
            officialFeeTypeId: 0,
            officialFeeOwnerTypeId: 0,
            name: '',
            searchTerm: '',
            recordNo: '',
            receiptNo: '',
            employeeOption: [],
            employeeSelect: 0,
            documentDateStart: null,
            documentDateEnd: null,
            receiptDateStart: null,
            receiptDateEnd: null,
            officialFeeTypeSelect: 0,
            officialFeeTypeOption: [],
            moduleSelect: 0,
            moduleOption: [],
            priceEnd: 0,
            priceStart: 0,
            creditCardSelect: 0,
            creditCardOption: [],
            officialFeeOwnerTypeOption: [],
            officialFeeOwnerTypeSelect: 0,
            officialFeeRefundStatusOption: [],
            officialFeeRefundStatusSelect: 0,
            currencySelect: 0,
            currencyOption: [],
        }
    },
    mounted() {
        this.getOfficialFeeList()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'OfficialFee'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getOfficialFeeList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getOfficialFeeList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.officialFeeFormModal.showInfo(id)
        },
        getOfficialFeeList() {
            this.rows = []
            const data = {
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                moduleId: this.moduleSelect,
                creditCardId: this.creditCardSelect,
                employeeId: this.employeeSelect,
                officialFeeTypeId: this.officialFeeTypeSelect,
                officialFeeOwnerTypeId: this.officialFeeOwnerTypeSelect,
                currencyId: this.currencySelect,
                officialFeeRefundStatusId: this.officialFeeRefundStatusSelect,
                priceStart: this.priceStart,
                priceEnd: this.priceEnd,
                recordNo: this.recordNo,
                receiptNo: this.receiptNo,
                receiptDateStart: this.receiptDateStart,
                receiptDateEnd: this.receiptDateEnd,
                documentDateStart: this.documentDateStart,
                documentDateEnd: this.documentDateEnd,
                name: this.name,
                officailFeeTypeId: this.officialFeeTypeId,
                documentNo: this.documentNo,
                clientAccounting: this.clientAccounting,
                name: this.name,
                currencyId: this.currencySelect
            }
            const controller = {
                name: 'OfficialFee'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                this.total = response.data.total
                if (response.data.resultStatus == true) {

                    this.employeeOption = response.data.employeeList
                    this.officialFeeTypeOption = response.data.officialFeeTypeList
                    this.officialFeeOwnerTypeOption = response.data.officialFeeOwnerTypeList
                    this.officialFeeRefundStatusOption = response.data.officialFeeRefundStatusList
                    this.moduleOption = response.data.moduleList
                    this.currencyOption = response.data.currencyList
                    this.creditCardOption = response.data.creditCardList
                    this.employeeOption.splice(0, 0, this.$nullSelected("name"))
                    this.officialFeeRefundStatusOption.splice(0, 0, this.$nullSelected("name"))
                    this.officialFeeOwnerTypeOption.splice(0, 0, this.$nullSelected("name"))
                    this.officialFeeTypeOption.splice(0, 0, this.$nullSelected("name"))
                    this.moduleOption.splice(0, 0, this.$nullSelected("name"))
                    this.currencyOption.splice(0, 0, this.$nullSelected("code"))
                    this.creditCardOption.splice(0, 0, this.$nullSelected("name"))

                    response.data.officialFeeList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'OfficialFee',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("OfficialFee", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clearSearchForm() {
            this.recordNo = '';
            this.receiptNo = '';
            this.employeeSelect = 0;
            this.officialFeeTypeSelect = 0;
            this.documentNo = '',
            this.clientAccounting = '',
            this.documentDateStart = null,
            this.documentDateEnd = null,
            this.receiptDateStart = null,
            this.receiptDateEnd = null,
            this.moduleSelect = 0,
            this.priceEnd = 0,
            this.priceStart = 0,
            this.creditCardSelect = 0,
            this.officialFeeOwnerTypeSelect = 0,
            this.officialFeeRefundStatusSelect = 0,
            this.currencySelect = 0,

            // Tabloyu yenile
            this.getOfficialFeeList();
        }
    },
}
</script>

<style lang="scss" scoped>
.mr-05 {
    margin-right: 0.5rem !important;
}

.custom-search {
    .btn {
        margin-left: 5px;
    }
}
</style>
