<template>
<div>
    <job-form :recordId="this.recordId" :getJobList="getJobList" ref="jobForm"></job-form>
    <job-detail-form :getJobList="getJobList" :recordNo="this.recordNo" ref="jobDetailForm"></job-detail-form>
    <job-next-form :recordId="this.recordId" :getJobList="getJobList" ref="jobNextForm"></job-next-form>
    <b-row>
        <b-col class="col-12">
            <div style="float:left;" class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-input size="sm" v-model="searchTerm" :placeholder="$t('others.search_name')" type="text" class="d-inline-block" />
                    </div>
                </b-form-group>
            </div>
            <div style="float:right;" class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-button style="float:right;" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('job.job_add') }}</b-button>
                    </div>
                </b-form-group>
            </div>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="jobTable" ref="jobTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" :pagination-options="{
        enabled: true,
        perPage: pageLength
      }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field ==='isDone'" class="text-nowrap" style="width: 0.01em;">
                {{ $t('employee.act_pass') }}
            </span>
            <span v-else-if="props.column.field === 'jobStatus'" class="text-nowrap">
                {{ $t('job.job_status') }}
            </span>
            <span v-else-if="props.column.field === 'serviceType'" class="text-nowrap">
                {{ $t('job.service_type') }}
            </span>
            <span v-else-if="props.column.field === 'date'" class="text-nowrap">
                {{ $t('job.date') }}
            </span>
            <span v-else-if="props.column.field === 'documentNo'" class="text-nowrap">
                {{ $t('job.note') }}
            </span>
            <span v-else-if="props.column.field === 'nextJobDeadlineDate'" class="text-nowrap">
                {{ $t('job.next_job_deadline_date') }}
            </span>
            <span v-else-if="props.column.field === 'tpmkDeadline'" class="text-nowrap">
                {{ $t('job.tpmk_deadline') }}
            </span>
            <span v-else-if="props.column.field === 'employee'" class="text-nowrap">
                {{ $t('job.employee') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'jobStatus'" class="text-nowrap">
                <b-row>
                    <b-col class="font-size: xx-small">
                        <span>{{ props.row.firstName }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small"><strong>{{ props.row.jobStatus }}</strong></span>
                    </b-col>
                </b-row>
            </span>
            <!-- Column: employee Name -->
            <span v-else-if="props.column.field == 'isDone'">
                <span v-if="props.row.isDone == false">
                    <feather-icon color="red" icon="CircleIcon" />
                </span>
                <span v-else-if="props.row.isDone == true">
                    <feather-icon color="green" icon="CircleIcon" />
                </span>
            </span>
            <!-- Column: Process -->
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.detail')" v-b-modal.modal-top variant="info" class="btn-icon mb-1 ml-1" size="sm" @click="getDetailInfo(props.row.id)">
                        <feather-icon icon="CastIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.next_process')" v-b-modal.modal-top variant="light" class="btn-icon mb-1 ml-1" size="sm" @click="getNextInfo(props.row.id)">
                        <feather-icon icon="ChevronsRightIcon" />
                    </b-button>
                    <!-- <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1"
                size="sm" @click="deleted(props.row)">
                <feather-icon icon="XIcon" />
              </b-button> -->
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
                    </span>
                    <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    props: {
        recordId: Number,
        moduleId: Number,
        documentId: Number,
        recordNo: String,
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            isDone: false,
            columns: [{
                    label: this.$t('employee.act_pass'),
                    field: `isDone`,
                    width: '50px',
                },
                {
                    label: this.$t('job.job_status'),
                    field: `jobStatus`,
                },
                {
                    label: this.$t('job.service_type'),
                    field: `serviceType`,
                },
                {
                    label: this.$t('job.date'),
                    field: `date`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('job.note'),
                    field: `note`,
                },
                {
                    label: this.$t('job.next_job_deadline_date'),
                    field: `nextJobDeadlineDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('job.tpmk_deadline'),
                    field: `tpmkDeadline`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('job.employee'),
                    field: `employee`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
            rowToIsDoneTrue: []
        }
    },
    mounted() {
        this.getJobList()
    },
    methods: {
        async getInfo(id) {
            this.$refs.jobForm.showInfo(id, this.moduleId, this.documentId)
        },
        async getDetailInfo(id) {
            this.$refs.jobDetailForm.getJobDetailList(id, this.recordNo)
        },
        async getNextInfo(id) {
            if (this.rowToIsDoneTrue.includes(id) == true) {
                this.$IdIsNotContinueToNextJob()
            } else {
                this.$refs.jobNextForm.showInfo(id, this.documentId, this.moduleId,this.recordNo)
            }
        },
        getJobList() {
            this.rows = []
            const data = {
                moduleId: this.moduleId,
                recordId: this.recordId
            }
            const controller = {
                name: 'Job',
                actionName: 'GetJobListByModuleAndRecordId'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.jobList.forEach(element => {
                        if (element.isDone == true) {
                            this.rowToIsDoneTrue.push(element.id)
                        }
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        getJobListWithRecordId(moduleId, recordId) {
            this.moduleId = moduleId
            this.recordId = recordId
            this.getJobList()
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
