<template>
<div>
    <domain-opposition-form :getDomainOppositionList="getDomainOppositionList" ref="domainOppositionFormModal"></domain-opposition-form>
    <div class="custom-search">
        <b-row>
            <b-col class="col-md-2">
                <label for="domain-opposition-record-no-input">{{ $t('domainOpposition.record_no') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="recordNo" v-on:keyup.enter="getDomainOppositionList()" :placeholder="$t('domainOpposition.record_no')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="domain-opposition-domain-name-input">{{ $t('domainOpposition.domain_name') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="domainName" v-on:keyup.enter="getDomainOppositionList()" :placeholder="$t('domainOpposition.domain_name')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="domain-opposition-agent-name-input">{{ $t('domainOpposition.agent_name') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="agent" v-on:keyup.enter="getDomainOppositionList()" :placeholder="$t('domainOpposition.agent_name')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="domain-opposition-owner-input">{{ $t('domainOpposition.owner') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="owner" v-on:keyup.enter="getDomainOppositionList()" :placeholder="$t('domainOpposition.owner')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-4 mt-2 custom-search d-flex justify-content-end">
                <div class="d-flex align-items-center">
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getDomainOppositionList()" @click="getDomainOppositionList()">{{ $t('others.list') }}</b-button>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('domainOpposition.add') }}</b-button>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="clearSearchForm"> {{ $t('others.clear') }}</b-button>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-collapse class="mb-1 mt-1" accordion type="margin">
                    <app-collapse-item :title="$t('others.search')">
                        <b-row>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="domain-opposition-employee-input">{{ $t('domainOpposition.employee') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input size="sm" v-model="employee" v-on:keyup.enter="getDomainOppositionList()" :placeholder="$t('domainOpposition.employee')" type="text" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="domain-opposition-trademark-input">{{ $t('domainOpposition.trademark') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input size="sm" v-model="trademark" v-on:keyup.enter="getDomainOppositionList()" :placeholder="$t('domainOpposition.trademark')" type="text" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="domain-opposition-appeal-deadline-start-input">{{ $t('domainOpposition.appeal_deadline_start') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="appealDeadlineStart"></CustomDatePicker>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-3">
                                <b-form-group>
                                    <label for="domain-opposition-appeal-deadline-end-input">{{ $t('domainOpposition.appeal_deadline_end') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="appealDeadlineEnd"></CustomDatePicker>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-3">
                                <label for="domain-opposition-holder-name-input">{{ $t('domainOpposition.holder_name') }}</label>
                                <div class="d-flex align-items-center">
                                    <b-form-input size="sm" v-model="holder" v-on:keyup.enter="getDomainOppositionList()" :placeholder="$t('domainOpposition.holder_name')" type="text" class="d-inline-block" />
                                </div>
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse> 
            </b-col>
        </b-row>
    </div>    
    <!-- table -->
    <vue-good-table id="domainOppositionTable" ref="domainOppositionTable" :columns="columns" :rows="rows" :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }" styleClass="vgt-table condensed tableSize" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }" :pagination-options="{
    enabled: true,
    perPage:pageLength
  }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'recordNo'" class="text-nowrap">
                {{ $t('domainOpposition.record_no') }}
            </span>
            <span v-else-if="props.column.field === 'domainName'" class="text-nowrap">
                {{ $t('domainOpposition.domain_name') }}
            </span>
            <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
                {{ $t('domainOpposition.agent_name') }}
            </span>
            <span v-else-if="props.column.field === 'holderName'" class="text-nowrap">
                {{ $t('domainOpposition.holder_name') }}
            </span>
            <span v-else-if="props.column.field === 'employee'" class="text-nowrap">
                {{ $t('domainOpposition.employee') }}
            </span>
            <span v-else-if="props.column.field === 'oppositionBasisList'" class="text-nowrap">
                {{ $t('domainOpposition.basis_for_opposition') }}
            </span>
            <span v-else-if="props.column.field === 'recordDate'" class="text-nowrap">
                {{ $t('domainOpposition.record_date') }}
            </span>
            <span v-else-if="props.column.field === 'appealDeadline'" class="text-nowrap">
                {{ $t('domainOpposition.appeal_deadline') }}
            </span>
            <span v-else-if="props.column.field === 'note'" class="text-nowrap">
                {{ $t('domainOpposition.note') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Agent Name -->
            <span v-if="props.column.field === 'agent'" class="text-nowrap">
                <b-row>
                    <b-col class="font-size: x-small">
                        <span>{{ props.row.agent }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small">{{ props.row.clientAccounting }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'oppositionBasisList'">
                <b-col>
                    <b-row v-for="oppositionBasis in props.row.oppositionBasisList" v-bind:data="oppositionBasis" v-bind:key="oppositionBasis.name" style="font-size: small;">
                        <tr v-if="props.row.oppositionBasisList.length != 1">
                            <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
                            <span>{{ oppositionBasis.name }} </span> <br />
                        </tr>
                        <tr v-else-if="props.row.oppositionBasisList.length == 1">
                            <span>{{ oppositionBasis.name }} </span> <br />
                        </tr>
                    </b-row>
                </b-col>
            </span>
            <!-- Column: Registration Date Format -->
            <span v-else-if="props.column.field === 'Record Date'" class="text-nowrap">
                <span>{{ props.row.recordDate }}</span>
            </span>
            <span v-else-if="props.column.field === 'Appeal Deadline'" class="text-nowrap">
                <span>{{ props.row.apperialDeadline }}</span>
            </span>
            <!-- Column: Process -->
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">

                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select size="sm" v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getDomainOppositionList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

    
<script>    
export default {
    data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            pageNo: 1,
            columns: [{
                    label: this.$t('domainOpposition.record_no'),
                    field: `recordNo`,
                },
                {
                    label: this.$t('domainOpposition.domain_name'),
                    field: `domainName`,
                },
                {
                    label: this.$t('domainOpposition.agent_name'),
                    field: `agent`,
                },
                {
                    label: this.$t('domainOpposition.holder_name'),
                    field: `holderName`,
                },
                {
                    label: this.$t('domainOpposition.employee'),
                    field: `employee`,
                },
                {
                    label: this.$t('domainOpposition.basis_for_opposition'),
                    field: 'oppositionBasisList'
                },
                {
                    label: this.$t('domainOpposition.record_date'),
                    field: `recordDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('domainOpposition.appeal_deadline'),
                    field: `appealDeadline`,
                    formatFn: this.$formatFn
                },
                // {
                //   label: 'Trademark',
                //   field: `tradermakName`,
                // },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },

            ],
            rows: [],
            searchTerm: '',
            recordNo: '',
            agent: '',
            domainName: '',
            employee: '',
            appealDeadlineEnd: null,
            appealDeadlineStart: null,
            holder: '',
            owner: '',
            trademark: '',
        }
    },
    mounted() {
        this.getDomainOppositionList()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'DomainOpposition'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getDomainOppositionList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getDomainOppositionList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.domainOppositionFormModal.showInfo(id)
        },
        getDomainOppositionList() {
            this.rows = []
            const controller = {
                name: 'DomainOpposition'
            }
            const data = {
                recordNo: this.recordNo,
                agent: this.agent,
                domainName: this.domainName,
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                employee: this.employee,
                appealDeadlineEnd: this.appealDeadlineEnd,
                appealDeadlineStart: this.appealDeadlineStart,
                trademark : this.trademark,
                holder: this.holder,
                owner : this.owner
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total

                    response.data.domainOppositionList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'DomainOpposition',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("AlanAdiİtirazlari", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clearSearchForm() {
            this.recordNo = '';
            this.domainName = '';
            this.agent = '';
            this.owner = '';
            this.employeeSelect = 0;
            this.domainOppositionStatusSelect = 0;
            this.applicationDateStart = null;
            this.applicationDateEnd = null;
            this.deadlineDateStart = null;
            this.deadlineDateEnd = null;
            this.employee = '',
            this.appealDeadlineEnd = null,
            this.appealDeadlineStart = null,
            this.holder = '',
            this.trademark = '',

            // Tabloyu yenile
            this.getDomainOppositionList();
        }
    },
}
</script>
    
<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

[dir] .dark-layout .card {
    background-color: #252d3f !important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
}

.akista-d-flex {
    display: contents !important;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}

.mr-05 {
    margin-right: 0.5rem !important;
}

.custom-search {
    .btn {
        margin-left: 5px;
    }
}
</style>
