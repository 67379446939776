var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"businessPartnerFormModal",attrs:{"id":"businessPartnerFormModal","centered":"","size":"xl","no-close-on-backdrop":"","title":_vm.$t('businessPartner.info'),"hide-footer":""},on:{"close":function($event){return _vm.clear()}}},[_c('client-invoice-report-list-call-form',{ref:"clientInvoiceReportListCallForm"}),_c('b-card',[_c('div',{staticClass:"custom-search d-flex justify-content-end mb-1"},[_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"ml-05",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.addOrUpdate($event)}}},[_vm._v(" "+_vm._s(_vm.$t('others.ok_title'))+" ")])],1)],1)],1),_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{staticClass:"col-md-8"},[_c('b-form',[_c('label',{attrs:{"for":"businessPartner-name-input"}},[_vm._v(_vm._s(_vm.$t('businessPartner.name')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('businessPartner.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"businessPartner-name-input","maxlength":"100","state":errors.length > 0 ? false:null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"col-md-4"},[_c('b-form',[_c('br'),_c('b-form-checkbox',{attrs:{"name":"checkbox"},model:{value:(_vm.isVisibleInDailywork),callback:function ($$v) {_vm.isVisibleInDailywork=$$v},expression:"isVisibleInDailywork"}},[_vm._v(" "+_vm._s(_vm.$t('businessPartner.is_visible_in_dailywork'))+" ")])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-md-3"},[_c('b-form',[_c('label',{attrs:{"for":"businessPartner-code-input"}},[_vm._v(_vm._s(_vm.$t('businessPartner.code')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('businessPartner.code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"businessPartner-code-input","maxlength":"50","state":errors.length > 0 ? false:null},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"col-md-3"},[_c('b-form',[_c('label',{attrs:{"for":"type-option"}},[_vm._v(_vm._s(_vm.$t('businessPartner.business_partner_type')))]),_c('validation-provider',{attrs:{"name":_vm.$t('businessPartner.business_partner_type'),"rules":{ excluded: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","value-field":"id","text-field":"name","options":_vm.businessPartnerTypeOption},model:{value:(_vm.businessPartnerTypeSelect),callback:function ($$v) {_vm.businessPartnerTypeSelect=$$v},expression:"businessPartnerTypeSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"col-md-3"},[_c('b-form',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('businessPartner.number'),"rules":{ excluded: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('businessPartner.number'),"label-for":"name","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"accountNo","options":_vm.accountOption,"label":"name"},on:{"input":_vm.setAccountId,"search":_vm.onSearch},model:{value:(_vm.accountSelect),callback:function ($$v) {_vm.accountSelect=$$v},expression:"accountSelect"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1),_c('b-col',{staticClass:"col-md-3"},[_c('b-form',[_c('label',{attrs:{"for":"businessPartner-tax-number-input"}},[_vm._v(_vm._s(_vm.$t('businessPartner.tax_number')))]),_c('b-form-group',[_c('b-form-input',{attrs:{"size":"sm","id":"businessPartner-tax-number-input"},model:{value:(_vm.taxNumber),callback:function ($$v) {_vm.taxNumber=$$v},expression:"taxNumber"}})],1)],1)],1)],1),_c('b-row',[_c('b-col')],1)],1)],1),_c('b-card',{attrs:{"hidden":_vm.hiddenControl}},[_c('b-row',[_c('b-col',{staticClass:"mt-2"},[_c('b-tabs',{attrs:{"pills":"","vertical":"","nav-wrapper-class":"nav-vertical"}},[_c('b-tab',{staticStyle:{"width":"100%"},attrs:{"title":_vm.$t('businessPartner.responsible')}},[_c('b-card-text',[_c('business-partner-responsible-list',{attrs:{"businessPartnerId":this.id}})],1)],1),_c('b-tab',{staticStyle:{"width":"100%"},attrs:{"title":_vm.$t('businessPartner.address')}},[_c('b-card-text',[_c('business-partner-address-list',{attrs:{"businessPartnerId":this.id}})],1)],1),_c('b-tab',{staticStyle:{"width":"100%"},attrs:{"title":_vm.$t('businessPartner.contact_information')}},[_c('b-card-text',[_c('business-partner-contact-information-list',{attrs:{"businessPartnerId":this.id}})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }