<template>
<div>

    <!-- modal -->
    <b-modal id="businessPartnerFormModal" centered size="xl" no-close-on-backdrop ref="businessPartnerFormModal" :title=" $t('businessPartner.info')" hide-footer @close="clear()">
        <client-invoice-report-list-call-form ref="clientInvoiceReportListCallForm"></client-invoice-report-list-call-form>
        <b-card>
            <div class="custom-search d-flex justify-content-end mb-1">
                <b-row>
                    <b-col>
                        <b-button variant="outline-primary" size="sm" class="ml-05" @click.prevent="addOrUpdate">
                            {{$t('others.ok_title')}}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col class="col-md-8">
                        <b-form>
                            <label for="businessPartner-name-input">{{ $t('businessPartner.name') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name=" $t('businessPartner.name')" rules="required">
                                    <b-form-input size="sm" id="businessPartner-name-input" maxlength="100" v-model="name" :state="errors.length > 0 ? false:null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>


                        <b-col class="col-md-4">
                        <b-form>
                            <br/>
                            <b-form-checkbox v-model="isVisibleInDailywork" name="checkbox">
                                {{ $t('businessPartner.is_visible_in_dailywork') }}
                            </b-form-checkbox>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="businessPartner-code-input">{{ $t('businessPartner.code') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name=" $t('businessPartner.code')" rules="required">
                                    <b-form-input size="sm" id="businessPartner-code-input" maxlength="50" v-model="code" :state="errors.length > 0 ? false:null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>

                    <b-col class="col-md-3">
                        <b-form>
                            <label for="type-option">{{ $t('businessPartner.business_partner_type') }}</label>
                            <validation-provider :name=" $t('businessPartner.business_partner_type')" #default="{ errors }" :rules="{ excluded: 0 }">
                                <b-form-select size="sm" v-model="businessPartnerTypeSelect" value-field="id" text-field="name" :options="businessPartnerTypeOption" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <b-form-group>
                                <validation-provider :name=" $t('businessPartner.number')" #default="{ errors }" :rules="{ excluded: 0 }">
                                    <b-form-group :label=" $t('businessPartner.number')" label-for="name" :state="errors.length > 0 ? false:null">
                                        <v-select id="accountNo" v-model="accountSelect" :options="accountOption" @input="setAccountId" label="name" @search="onSearch"> </v-select>
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null"> {{ errors[0] }} </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>

                    <b-col class="col-md-3">
                        <b-form>
                            <label for="businessPartner-tax-number-input">{{ $t('businessPartner.tax_number') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="businessPartner-tax-number-input" v-model="taxNumber" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>

                    </b-col>
                </b-row>
            </validation-observer>
        </b-card>
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab :title=" $t('businessPartner.responsible')" style="width:100%;">
                            <b-card-text>
                                <business-partner-responsible-list :businessPartnerId="this.id"></business-partner-responsible-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('businessPartner.address')" style="width:100%;">
                            <b-card-text>
                                <business-partner-address-list :businessPartnerId="this.id"></business-partner-address-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('businessPartner.contact_information')" style="width:100%;">
                            <b-card-text>
                                <business-partner-contact-information-list :businessPartnerId="this.id"></business-partner-contact-information-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getBusinessPartnerList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            subsidiaryLedgerId: 0,
            businessPartnerTypeSelect: 0,
            businessPartnerTypeOption: [],
            hiddenControl: true,
            name: '',
            code: '',
            taxNumber: '',
            number: '',
            accountSelect: 0,
            accountOption: [],
            isVisibleInDailywork: false,
        }
    },
    methods: {
        setAccountId(event) {
            if (event != null) {
                this.accountingId = event.id
                this.currencySelect = event.currencyId
            } else {
                this.accountingId = null;
                this.currencySelect = 0;
            }
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    id: 0,
                    number: search,
                    subsidiaryLedgerId: this.subsidiaryLedgerId
                }
                const controller = {
                    name: 'Accounting',
                    actionName: 'SearchParentAccounting'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.accountOption = response.data.accountingList
                    loading(false)
                })
            }
        },
        showInfo(id, subsidiaryLedgerId) {
            this.subsidiaryLedgerId = subsidiaryLedgerId
            this.$refs['businessPartnerFormModal'].show()
            this.id = id
            if (this.id > 0) {
                this.hiddenControl = false
            } else {
                this.hiddenControl = true
            }
            const data = {
                id: this.id
            }
            const controller = {
                name: 'BusinessPartner'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.businessPartnerTypeOption = response.data.businessPartnerTypeList
                    this.name = response.data.businessPartner.name
                    this.code = response.data.businessPartner.code
                    this.taxNumber = response.data.businessPartner.taxNumber
                    this.number = response.data.businessPartner.number
                    this.isVisibleInDailywork = response.data.businessPartner.isVisibleInDailywork
                    if (response.data.businessPartner.businessPartnerTypeId > 0) {
                        this.businessPartnerTypeSelect = response.data.businessPartner.businessPartnerTypeId

                    } else {
                        this.businessPartnerTypeSelect = 0
                    }
                    this.businessPartnerTypeOption.splice(0, 0, this.$nullSelected())

                    if (response.data.businessPartner.accountingId > 0) {
                        const accountingData = {
                            id: response.data.businessPartner.accountingId
                        }
                        const accountingController = {
                            name: 'Accounting',
                            actionName: 'SearchParentAccounting'
                        }
                        const accountingPayload = {
                            data: accountingData,
                            controller: accountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', accountingPayload).then((accountingResponse) => {
                            this.accountOption = accountingResponse.data.accountingList
                            this.accountSelect = accountingResponse.data.accountingList[0]
                            this.accountingId = accountingResponse.data.accountingList[0].id
                        })
                    } else {
                        this.accountOption = []
                        this.accountSelect = 0;
                    }
                } else {
                    this.$bvModal.hide('businessPartnerFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clear() {
            this.businessPartnerTypeSelect = 0
            this.name = '',
                this.code = '',
                this.taxNumber = '',
                this.number = ''
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                accountingId: this.accountingId,
                name: this.name,
                taxNumber: this.taxNumber,
                number: this.number,
                code: this.code,
                businessPartnerTypeId: this.businessPartnerTypeSelect,
                isVisibleInDailywork: this.isVisibleInDailywork
            }
            const controller = {
                name: 'BusinessPartner'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getBusinessPartnerList()
                            this.$SaveAlert()
                            if (this.id == 0) {
                                this.id = response.data.businessPartnerId
                                this.hiddenControl = false
                            }
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .dark-layout .modal .modal-content,
[dir] .dark-layout .modal .modal-body,
[dir] .dark-layout .modal .modal-footer {
    background-color: #272e3f !important;
    border-color: #3b4253;
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}

.dropdown-scroll {
    max-height: 200px;
    /* Dropdown içeriği için maksimum yükseklik */
    overflow-y: auto;
    /* Yatay kaydırma çubuğunu etkinleştir */
}

.agentInput {
    max-width: 87em !important;
    width: 86em !important;
}
</style>
