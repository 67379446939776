<template>
<div>
    <invoice-form ref="invoiceForm"></invoice-form>
    <patent-form ref="patentForm"></patent-form>
    <national-patent-form ref="nationalPatentForm"></national-patent-form>
    <trademark-form ref="trademarkForm"></trademark-form>
    <trademark-search-form ref="trademarkSearchForm"></trademark-search-form>
    <trademark-opposition-form ref="trademarkOppositionForm"></trademark-opposition-form>
    <national-trademark-form ref="nationalTrademarkForm"></national-trademark-form>
    <design-form ref="designForm"></design-form>
    <design-opposition-form ref="designOppositionForm"></design-opposition-form>
    <domain-form ref="domainForm"></domain-form>
    <domain-opposition-form ref="domainOppositionForm"></domain-opposition-form>
    <job-next-form :getJobReportList="getJobReportList" ref="jobNextForm"></job-next-form>
    <job-update-note-form :recordId="this.recordId" :getJobReportList="getJobReportList" ref="jobUpdateNoteForm"></job-update-note-form>
    <div class="custom-search mb-1">
        <b-row>
            <b-col class="col-md-9">
                <h4>{{ $t('permissionsPendingApproval.job_report') }}</h4>
            </b-col>
            <b-col class="col-md-3 custom-search d-flex justify-content-end">
                <div class="d-flex align-items-center">
                    <b-button style="float:right;" class="mr-05" size="sm" v-on:keyup.enter="getJobReportList()" variant="outline-primary" @click="getJobReportList()">{{$t('jobReport.list')}}</b-button>
                    <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()">{{$t('excel.excel') }}</b-button>
                </div>
            </b-col>
        </b-row>
        <b-row class=" mt-1">
            <b-col class="col-md-2">
                <label for="job-report-employee-end">{{ $t('jobReport.employee') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-select @change="getJobReportList()" v-model="employeeSelect" size="sm" value-field="id" text-field="name" :options="employeeOption" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="job-report-relation-end">{{ $t('jobReport.relation') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-select @change="getJobReportList()" v-model="jobStatusRelationSelect" size="sm" value-field="id" text-field="jobStatus" :options="jobStatusRelationOption" />
                </div>
            </b-col>
            <b-col class="col-2">
                <label for="job-report-next-job-deadline-end">{{ $t('jobReport.tpmk_deadline_start') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-datepicker today-button size="sm" v-model="tpmkDeadlineStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" label-no-date-selected="" />
                </div>
            </b-col>
            <b-col class="col-2">
                <label for="job-report-tpmk-deadline-end">{{ $t('jobReport.tpmk_deadline_end') }}</label>
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-datepicker today-button size="sm" v-model="tpmkDeadlineEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" label-no-date-selected="" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col class="col-2">
                <label for="job-report-next-job-deadline-end">{{ $t('jobReport.next_job_deadline_start') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-datepicker today-button size="sm" v-model="nextJobDeadlineStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" label-no-date-selected="" />
                </div>
            </b-col>
            <b-col class="col-2">
                <label for="job-report-tpmk-deadline-end">{{ $t('jobReport.next_job_deadline_end') }}</label>
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-datepicker today-button size="sm" v-model="nextJobDeadlineEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" label-no-date-selected="" />
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
    <!-- table -->
    <vue-good-table id="jobReportTable" ref="jobReportTable" :columns="columns" :rows="rows" :sort-options="{
            enabled: false,
          }" styleClass="vgt-table condensed tableSize" :search-options="{
            enabled: true,
            externalQuery: searchTerm}" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }" :pagination-options="{
              enabled: true,
              perPage: pageLength
            }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'employee'" class="text-nowrap">
                {{ $t('jobReport.employee') }}
            </span>
            <span v-else-if="props.column.field === 'module'" class="text-nowrap">
                {{ $t('jobReport.module') }}
            </span>
            <span v-else-if="props.column.field === 'nextJobDeadlineDate'" class="text-nowrap">
                {{ $t('jobReport.next_job_deadline') }}
            </span>
            <span v-else-if="props.column.field === 'tpmkDeadline'" class="text-nowrap">
                {{ $t('jobReport.tpmk_deadline') }}
            </span>
            <span v-else-if="props.column.field === 'jobStatus'" class="text-nowrap">
                {{ $t('jobReport.job') }}
            </span>
            <span v-else-if="props.column.field ==='process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
            <span v-else-if="props.column.field ==='operationRecord.applicationNo'" class="text-nowrap">
                {{ $t('jobReport.operationRecord_applicationNo') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props" :class="classRow">

            <!-- Column: employee Name -->
            <span v-if="props.column.field === 'jobStatus'" class="text-nowrap">
                <span style="font-weight:bold;">{{ props.row.firstName }}</span>
                <br />
                <span :style="[ props.row.jobStatusPath.indexOf('-IN') >-1 ? { 'color': 'red' } : '']">{{ props.row.jobStatus }}</span>
            </span>
            <span v-else-if="props.column.field === 'operationRecord.applicationNo'">
                <span style="font-weight:bold;">{{ props.row.operationRecord.applicationNo }}</span>
                <br />
                <span>{{ props.row.operationRecord.title }}</span>
                <br />
                <span style="color: orange;">{{
                    props.row.operationRecord.note
                 }}
                </span>
            </span>
            <span v-else-if="props.column.field === 'invoiceList'">
                <b-col>
                    <b-row v-for="invoice in props.row.invoiceList" v-bind:data="invoice" v-bind:key="invoice.id" style="font-size: small;">
                        <span :style="[ invoice.paymentId == 0 ? { 'color': 'red','cursor': 'pointer' } : {'cursor': 'pointer'}]" @click="showInvoiceForm(invoice)"> <u>{{ invoice.debitNo }} </u></span> <br />
                    </b-row>
                </b-col>
            </span>

            <span v-else-if="props.column.field === 'nextJobDeadlineDate'" class="text-nowrap">
                <span>{{ $formatFn(props.row.nextJobDeadlineDate) }}</span>
                <br />
                <span :style="[ $CalculateTheDayCount(new Date(props.row.nextJobDeadlineDate), new Date()) < 0 ? { 'color': 'red' } : '']">{{ $CalculateTheDayCount(new Date(props.row.nextJobDeadlineDate), new Date()) }}</span>
            </span>

            <span v-else-if="props.column.field === 'tpmkDeadline'" class="text-nowrap">
                <span>{{ $formatFn(props.row.tpmkDeadline) }}</span>
                <br />
                <span v-if="props.row.tpmkDeadline != null" :style="[ $CalculateTheDayCount(new Date(props.row.tpmkDeadline), new Date()) < 0 ? { 'color': 'red' } : '']">{{ $CalculateTheDayCount(new Date(props.row.tpmkDeadline), new Date()) }}</span>
            </span>

            <!-- Column: recordNo -->
            <span v-else-if="props.column.field === 'operationRecord.recordNo'" class="text-nowrap">
                <b-badge variant="primary" style="cursor: pointer;" @click="showOperationForm(props.row)">
                    <feather-icon icon="MousePointerIcon" class="mr-25" />
                    <span>{{ props.row.operationRecord.recordNo }}</span>
                </b-badge>
                <br />
                <span>{{ props.row.jobStatusPath }}</span>
            </span>
            <!-- Column: Process -->
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.next_process')" v-b-modal.modal-top variant="light" class="btn-icon mb-1 ml-1" size="sm" @click="getNextInfo(props.row)">
                        <feather-icon icon="ChevronsRightIcon" />
                    </b-button>
                    <span v-if="props.row.isDone == false">
                        <b-button v-b-tooltip.html :title="$t('others.note')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getJobNoteInfo(props.row.jobHistoryId)">
                            <feather-icon icon="EditIcon" />
                        </b-button>
                    </span>
                </span>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }} </span>
                    <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>

    </vue-good-table>
</div>
</template>

<script>
export default {
    watch: {
        nextJobDeadlineStart(newValue) {
            if (newValue == '') {
                this.nextJobDeadlineStart = null
            }
        },
        nextJobDeadlineEnd(newValue) {
            if (newValue == '') {
                this.nextJobDeadlineEnd = null
            }
        },
        tpmkDeadlineStart(newValue) {
            if (newValue == '') {
                this.tpmkDeadlineStart = null
            }
        },
        tpmkDeadlineEnd(newValue) {
            if (newValue == '') {
                this.tpmkDeadlineEnd = null
            }
        }
    },
    data() {
        return {
            id: 0,
            employee: '',
            nowDate: null,
            employeeOption: [],
            employeeSelect: 0,
            moduleOption: [],
            moduleSelect: 0,
            jobStatusRelationOption: [],
            jobStatusRelationSelect: 0,
            nextJobDeadlineStart: null,
            nextJobDeadlineEnd: null,
            tpmkDeadlineEnd: null,
            tpmkDeadlineStart: null,
            isDone: false,
            columns: [{
                    label: this.$t('jobReport.record_no'),
                    field: `operationRecord.recordNo`,
                    sortable: false
                },
                {
                    label: this.$t('jobReport.operationRecord_applicationNo'),
                    field: `operationRecord.applicationNo`,
                    sortable: false
                },
                {
                    label: this.$t('jobReport.job'),
                    field: `jobStatus`,
                    sortable: false
                },
                {
                    label: this.$t('jobReport.next_job_deadline'),
                    field: `nextJobDeadlineDate`,
                    sortable: false
                },
                {
                    label: this.$t('jobReport.tpmk_deadline'),
                    field: `tpmkDeadline`,
                    sortable: true
                },
                {
                    label: this.$t('jobReport.employee'),
                    field: `employee`,
                    sortable: false
                },
                {
                    label: this.$t('jobReport.invoiceList'),
                    field: `invoiceList`,
                    sortable: false
                },
                {
                    label: this.$t('jobReport.note'),
                    field: `note`,
                    sortable: false
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em',
                    sortable: false
                },
            ],
            rows: [],
            total: 0,
            pageLength: 10,
            pageNo: 1,
            dir: false,
            searchTerm: '',
            rowToIsDoneTrue: [],
            recordId : 0,
        }
    },
    mounted() {
        this.employee = localStorage.getItem('EmployeeId')
        if (this.employeeSelect == 0) {
            this.employeeSelect = this.employee
        }
        this.getJobReportList()
    },
    methods: {
        async getNextInfo(row) {
            //STOCK-1454 JobNextForm.showInfo Method accepts , showInfo(jobId, documentId, moduleId) , moduleSelect applied to wrong parameter.  
            this.$refs.jobNextForm.showInfo(row.id, 0, this.moduleSelect, row.operationRecord.recordNo)
            //Need to use it carefully. Nevzat 01.01.2025
        },
        async showInvoiceForm(invoice) {
            this.$refs.invoiceForm.showInfo(invoice.id)
        },
        getJobReportList() {
            this.rows = []
            const controller = {
                name: 'Job',
                actionName: 'GetJobReportList'
            }
            const data = {
                employeeId: this.employeeSelect,
                pageLength: 1000,
                pageNo: 1,
                moduleId: this.moduleSelect,
                jobStatusRelationId: this.jobStatusRelationSelect,
                nextJobDeadlineStart: this.nextJobDeadlineStart,
                nextJobDeadlineEnd: this.nextJobDeadlineEnd,
                tpmkDeadlineStart: this.tpmkDeadlineStart,
                tpmkDeadlineEnd: this.tpmkDeadlineEnd,
                isDone: this.isDone
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.employeeOption = response.data.employeeList
                    this.moduleOption = response.data.moduleList
                    this.jobStatusRelationOption = response.data.jobStatusRelationList
                    this.employeeOption.splice(0, 0, this.$nullSelected())
                    this.moduleOption.splice(0, 0, this.$nullSelected())
                    this.jobStatusRelationOption.splice(0, 0, this.$nullSelected('jobStatus'))
                    this.total = response.data.total
                    response.data.jobReportList.forEach(element => {
                        this.id = element.id
                        if (element.isDone == true) {
                            this.rowToIsDoneTrue.push(element.id)
                        }
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        showOperationForm(row) {
            if (row.moduleId == 2) {
                this.$refs.trademarkForm.showInfo(row.recordId)
            } else if (row.moduleId == 3) {
                this.$refs.trademarkSearchForm.showInfo(row.recordId)
            } else if (row.moduleId == 4) {
                this.$refs.patentForm.showInfo(row.recordId)
            } else if (row.moduleId == 5) {
                this.$refs.designForm.showInfo(row.recordId)
            } else if (row.moduleId == 6) {
                this.$refs.trademarkOppositionForm.showInfo(row.recordId)
            } else if (row.moduleId == 7) {
                this.$refs.domainForm.showInfo(row.recordId)
            } else if (row.moduleId == 8) {
                this.$refs.domainForm.showInfo(row.recordId)
            } else if (row.moduleId == 9) {
                this.$refs.designOppositionForm.showInfo(row.recordId)
            } else if (row.moduleId == 15) {
                this.$refs.nationalTrademarkForm.showInfo(row.recordId)
            } else if (row.moduleId == 16) {
                this.$refs.nationalPatentForm.showInfo(row.recordId)
            }
        },
        exportExcel() {

            var exelColumns = [{
                    label: this.$t('jobReport.record_no'),
                    field: `operationRecord.recordNo`,
                },
                {
                    label: this.$t('jobReport.operationRecord_applicationNo'),
                    field: `operationRecord.applicationNo`,
                },
                {
                    label: this.$t('jobReport.operationRecord_title'),
                    field: `operationRecord.title`,
                },
                {
                    label: this.$t('jobReport.job'),
                    field: `firstName`,
                },
                {
                    label: this.$t('jobReport.jobStatus'),
                    field: `jobStatus`,
                },
                {
                    label: this.$t('jobReport.next_job_deadline'),
                    field: `nextJobDeadlineDate`,
                },
                {
                    label: this.$t('jobReport.tpmk_deadline'),
                    field: `tpmkDeadline`,
                },
                {
                    label: this.$t('jobReport.employee'),
                    field: `employee`,
                },
                //{
                //  label: this.$t('jobReport.invoiceList'),
                // field: `invoiceList`,
                //}, 
                {
                    label: this.$t('jobReport.note'),
                    field: `note`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '7em'
                },
            ]

            const controller = {
                name: 'Job',
                actionName: 'CanExport'
            }
            const data = {
                columnList: exelColumns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Job", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        getJobNoteInfo(id) {
            this.$refs['jobUpdateNoteForm'].showInfo(id, this.jobId)
        },
    },

}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
