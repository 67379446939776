<template>
<div>
    <accounting-books-receipt-form :subsidiaryLedgerId="subsidiaryLedgerId" ref="accountingBooksReceiptFormModal"></accounting-books-receipt-form>
    <div class="custom-search">
        <b-row>
            <b-col class="col-md-2 mb-1">
                <label for="date-input">{{ $t('accountingTransactionZeroSequenceNoReport.date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="date"></CustomDatePicker>
            </b-col>
            <b-col class="col-md-10 custom-search d-flex justify-content-end">
                <div class="d-flex align-items-center">
                    <b-button class="d-inline-block mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getAccountingTransactionZeroSequenceNoReportList()" @click="getAccountingTransactionZeroSequenceNoReportList()">{{ $t('others.list') }}</b-button>
                    <b-button style="float:right;" class="ml-1" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                    <b-button :disabled=this.assignCheck style="float:right;" class="ml-1" size="sm" variant="outline-primary" @click="assignSequenceNo()">{{ $t('accountingTransactionZeroSequenceNoReport.assign_sequence_no') }}</b-button>
                </div>
            </b-col>
        </b-row>
    </div>
    <!-- table -->
    <vue-good-table ref="accountingTransactionZeroSequenceNoReportList" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
                          enabled: false,
                          externalQuery: searchTerm }" :select-options="{
                          enabled: false,
                          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                          selectionInfoClass: 'custom-class',
                          selectionText: 'rows selected',
                          clearSelectionText: 'clear',
                          disableSelectInfo: true, // disable the select info panel on top
                          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                        }" :pagination-options="{
                          enabled: true,
                          perPage:pageLength
                        }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field ==='number'" class="text-nowrap">
                {{ $t('accountingTransactionZeroSequenceNoReport.number') }}
            </span>
            <span v-else-if="props.column.field === 'accountingTransactionType'" class="text-nowrap">
                {{ $t('accountingTransactionZeroSequenceNoReport.transaction_type') }}
            </span>
            <span v-else-if="props.column.field ==='date'" class="text-nowrap">
                {{ $t('accountingTransactionZeroSequenceNoReport.transaction_date') }}
            </span>
            <span v-else-if="props.column.field ==='description'" class="text-nowrap">
                {{ $t('accountingTransactionZeroSequenceNoReport.transaction_description') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                </span>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
                    </span>
                    <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    data() {
        return {
            pageLength: 10,
            searchTerm: '',
            dir: false,
            pageNo: 1,
            name: '',
            startDate: null,
            endDate: null,
            rows: [],
            columns: [{
                    label: this.$t('accountingTransactionZeroSequenceNoReport.number'),
                    field: `number`,
                },
                {
                    label: this.$t('accountingTransactionZeroSequenceNoReport.transaction_date'),
                    field: `date`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('accountingTransactionZeroSequenceNoReport.transaction_type'),
                    field: `accountingTransactionType`,
                },
                {
                    label: this.$t('accountingTransactionZeroSequenceNoReport.transaction_description'),
                    field: `description`,
                },
                {
                    label: this.$t('accountingTransactionZeroSequenceNoReport.amount'),
                    field: `amount`,
                },
                {
                    label: this.$t('accountingTransactionZeroSequenceNoReport.employee'),
                    field: `employee`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            accountingTransactionZeroSequenceNoReportList: [],
            maxSequenceNo: 0,
            date: null,
            assignCheck: true,
            tokenEmployeeId: 0,
        }

    },
    props: {
        subsidiaryLedgerId: {
            type: Number
        }
    },
    mounted() {
        this.date = new Date().toJSON()
        this.getAccountingTransactionZeroSequenceNoReportList()
    },
    methods: {
        getAccountingTransactionZeroSequenceNoReportList() {
            this.rows = []
            const controller = {
                name: 'AccountingTransaction',
                actionName: 'AccountingTransactionZeroSequenceNoReport'
            }
            const data = {
                date: this.date,
                subsidiaryLedgerId: this.subsidiaryLedgerId,
            }
            const payload = {
                data: data,
                controller: controller
            }
            let loader = this.$loading.show()
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.accountingTransactionZeroSequenceNoReportList = []
                    this.maxSequenceNo = 0
                    if (response.data.accountingTransactionZeroSequenceNoReportList.length > 0) {
                        this.assignCheck = false;
                    }
                    this.maxSequenceNo = response.data.maxSequenceNo
                    response.data.accountingTransactionZeroSequenceNoReportList.forEach(element => {
                        this.rows.push(element)
                        this.accountingTransactionZeroSequenceNoReportList.push(element.id)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
            loader.hide()

        },
        async getInfo(id) {
            this.$refs.accountingBooksReceiptFormModal.showInfo(id)
        },
        exportExcel() {
            const controller = {
                name: 'AccountingTransaction',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                dataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Sequence No Raporu", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        assignSequenceNo() {
            this.$PrivateConfirm(this.$t("accountingTransactionZeroSequenceNoReport.max_sequence_no") + this.maxSequenceNo, this.$t("alerts.sequence_no_will_be_applied_all_records_in_list"), "warning", this.$t("others.ok_title"), this.$t("others.cancel_title")).then(result => {
                if (result.value) {
                    this.tokenEmployeeId = localStorage.getItem('EmployeeId')
                    const controller = {
                        name: 'AccountingTransaction',
                        actionName: 'AccountingTransactionAssignSequenceNo'
                    }
                    const data = {
                        accountingTransactionAssignSequenceNoList: this.accountingTransactionZeroSequenceNoReportList,
                        maxSequenceNo: this.maxSequenceNo,
                        employeeId: this.tokenEmployeeId
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$SaveAlert()
                            this.getAccountingTransactionZeroSequenceNoReportList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })

                }
            })
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
