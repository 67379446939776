<template>
<div>
    <trademark-opposition-form :getTrademarkOppositionList="getTrademarkOppositionList" ref="trademarkOppositionFormModal"></trademark-opposition-form>
    <b-row class="align-items-center">
        <b-col class="d-flex align-items-center col-lg-8">
            <b-col class="col-lg-3">
                <label for="trademark-opposition-trademark-opposition-folder-no-input">{{ $t('trademarkOpposition.trademark_opposition_folder_no') }}</label>
                <b-form-input size="sm" v-model="recordNo" v-on:keyup.enter="getTrademarkOppositionList()" :placeholder="$t('trademarkOpposition.trademark_opposition_folder_no')" type="text" class="d-inline-block" />
            </b-col>

            <b-col class="col-3">
                <label for="trademark-opposition-basis-trademark-input">{{ $t('trademarkOpposition.basis_trademark') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="basisForOpposition" v-on:keyup.enter="getTrademarkList()" :placeholder="$t('trademarkOpposition.basis_trademark')" type="text" class="d-inline-block" />
                </div>
            </b-col>

            <b-col class="col-lg-3">
                <label for="trademark-opposition-agent-input">{{ $t('trademarkOpposition.agent') }}</label>
                <b-form-input size="sm" v-model="agent" v-on:keyup.enter="getTrademarkOppositionList()" :placeholder="$t('trademarkOpposition.agent')" type="text" class="d-inline-block" />
            </b-col>
            <b-col class="col-lg-3">
                <label for="trademark-opposition-opposition-status-input">{{ $t('trademarkOpposition.opposition_status') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-select v-model="oppositionStatusSelect" size="sm" value-field="id" text-field="name" :options="oppositionStatusOption" />
                </div>
            </b-col>
        </b-col>
        <b-col class="col-md-4 mt-2 custom-search d-flex justify-content-end">
            <div class="d-flex align-items-right">
                <b-button size="sm" variant="outline-primary" class="mr-05" v-on:keyup.enter="getTrademarkOppositionList()" @click="getTrademarkOppositionList()">{{ $t('others.list') }}</b-button>
                <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('trademarkOpposition.trademark_opposition_add') }}</b-button>
                <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="clearSearchForm"> {{ $t('others.clear') }}</b-button>
            </div>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <app-collapse class="mb-1 mt-1" accordion type="margin">
                <app-collapse-item :title="$t('others.search')">
                    <b-row>
                        <b-col class="col-3">
                            <label for="trademark-opposition-application-owner-input">{{ $t('trademarkOpposition.application_owner') }}</label>
                            <div class="d-flex align-items-center">
                                <b-form-input size="sm" v-model="owner" v-on:keyup.enter="getTrademarkList()" :placeholder="$t('trademarkOpposition.application_owner')" type="text" class="d-inline-block" />
                            </div>
                        </b-col>
                        <b-col class="col-3">
                            <label for="trademark-opposition-application-trademark-input">{{ $t('trademarkOpposition.application_trademark') }}</label>
                            <div class="d-flex align-items-center">
                                <b-form-input size="sm" v-model="applicationTrademark" v-on:keyup.enter="getTrademarkList()" :placeholder="$t('trademarkOpposition.application_trademark')" type="text" class="d-inline-block" />
                            </div>
                        </b-col>
                        <b-col class="col-2">
                            <label for="trademark-opposition-classes-input">{{ $t('trademarkOpposition.classes') }}</label>
                            <div class="d-flex align-items-center">
                                <b-form-input size="sm" v-model="classes" v-on:keyup.enter="getTrademarkList()" :placeholder="$t('trademarkOpposition.classes')" type="text" class="d-inline-block" />
                            </div>
                        </b-col>

                        <b-col class="col-2">
                            <label for="trademark-opposition-employee-input">{{ $t('trademarkOpposition.employee') }}</label>
                            <div class="d-flex align-items-center">
                                <b-form-select v-model="employeeSelect" size="sm" value-field="id" text-field="name" :options="employeeOption" />
                            </div>
                        </b-col>

                        <b-col class="col-2">
                            <label for="trademark-opposition-bulletin-no-input">{{ $t('trademarkOpposition.bulletin_no') }}</label>
                            <div class="d-flex align-items-center">
                                <b-form-select v-model="bulletinNoSelect" size="sm" value-field="id" text-field="no" :options="bulletinNoOption" />
                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="col-3 mt-1">
                            <label for="trademark-opposition-basis-owner-input">{{ $t('trademarkOpposition.bases_owner') }}</label>
                            <div class="d-flex align-items-center">
                                <b-form-input size="sm" v-model="owner1" v-on:keyup.enter="getTrademarkList()" :placeholder="$t('trademarkOpposition.bases_owner')" type="text" class="d-inline-block" />
                            </div>
                        </b-col>
                        <!-- Dont know this field on TrademarkOpposition. Please Inform me about it . Nevzat.  -->


                        <b-col class="col-3 mt-1">
                            <label for="trademark-opposition-basis-trademark-ref-input">{{ $t('trademarkOpposition.basis_trademark_ref') }}</label>
                            <div class="d-flex align-items-center">
                                <b-form-input size="sm" v-model="basisForOppositionTrademarkRecordNo" v-on:keyup.enter="getTrademarkList()" :placeholder="$t('trademarkOpposition.basis_trademark_ref')" type="text" class="d-inline-block" />
                            </div>
                        </b-col>

                        <b-col class="col-2 mt-1">
                            <label for="trademark-opposition-attorney-name-input">{{ $t('trademarkOpposition.attorney_name') }}</label>
                            <b-form-input size="sm" v-model="attorneyName" v-on:keyup.enter="getTrademarkOppositionList()" :placeholder="$t('trademarkOpposition.attorney_name')" type="text" class="d-inline-block" />
                        </b-col>

                        <b-col class="col-2 mt-1">
                            <b-form-group>
                                <label for="trademark-opposition-deadline-start-input">{{ $t('trademarkOpposition.deadline_start') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademarkOpposition.deadline_start')" size="sm" v-model="deadlineStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-form-group>
                        </b-col>
                        <b-col class="col-2 mt-1">
                            <b-form-group>
                                <label for="trademark-opposition-deadline-end-input">{{ $t('trademarkOpposition.deadline_end') }}</label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('trademarkOpposition.deadline_end')" size="sm" v-model="deadlineEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </app-collapse-item>
            </app-collapse>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="trademarkOppositionTable" ref="trademarkOppositionTable" :columns="columns" :rows="rows" :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }" styleClass="vgt-table condensed tableSize" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }" :pagination-options="{
            enabled: true,
            perPage: pageLength
          }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'logo'" class="text-nowrap">
                {{ $t('trademarkOpposition.logo') }}
            </span>
            <span v-else-if="props.column.field === 'recordNo'" class="text-nowrap">
                {{ $t('trademarkOpposition.trademark_opposition_folder_no') }}
            </span>
            <span v-else-if="props.column.field === 'applicationNo'" class="text-nowrap">
                {{ $t('trademarkOpposition.application_no') }}
            </span>
            <span v-else-if="props.column.field === 'bulletinNo'" class="text-nowrap">
                {{ $t('trademarkOpposition.bulletin_no') }}
            </span>
            <span v-else-if="props.column.field === 'bulletinDate'" class="text-nowrap">
                {{ $t('trademarkOpposition.bulletin_date') }}
            </span>
            <span v-else-if="props.column.field === 'name'" class="text-nowrap">
                {{ $t('trademarkOpposition.name') }}
            </span>
            <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
                {{ $t('trademarkOpposition.agent_name') }}
            </span>
            <span v-else-if="props.column.field === 'ownerList'" class="text-nowrap">
                {{ $t('trademarkOpposition.owners') }}
            </span>
            <span v-else-if="props.column.field === 'trademarkOppositionStatus'" class="text-nowrap">
                {{ $t('trademarkOpposition.status') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Logo -->
            <span v-if="props.column.field === 'logo'" class="text-nowrap">
                <b-row>
                    <b-col v-if="props.row.logo != null" class="font-size: x-small">
                        <b-img>{{ 'data:image/jpeg;base64,'.concat(props.row.logo) }}</b-img>
                        <b-img :src="'data:image/jpeg;base64,'.concat(props.row.logo)" fluid alt="Responsive image" />
                    </b-col>
                </b-row>
            </span>
            <!-- Column: Agent Name -->
            <span v-else-if="props.column.field === 'agent'" class="text-nowrap">
                <b-row>
                    <b-col class="font-size: x-small">
                        <span>{{ props.row.agent }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small">{{ props.row.clientAccounting }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'ownerList'">
                <b-col>
                    <b-row v-for="owner in props.row.ownerList" v-bind:data="owner" v-bind:key="owner.Id" style="font-size: small;">
                        <tr v-if="props.row.ownerList.length != 1">
                            <feather-icon class="featherIconReSize" icon="ArrowRightIcon" />
                            <span>{{ owner.clientName }} </span> <br />
                        </tr>
                        <tr v-else-if="props.row.ownerList.length == 1">
                            <span>{{ owner.clientName }} </span> <br />
                        </tr>
                    </b-row>
                </b-col>
            </span>
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">

                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select size="sm" v-model="pageLength" :options="['10','50']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <!-- ,'100', '1000' 
                         Bu alanları ben kaldırdım - Nevzat Peker 11/07/2023 -->
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getTrademarkOppositionList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    watch: {
        deadlineEnd(newValue) {
            if (newValue == '') {
                this.deadlineEnd = null
            }
        },
        deadlineStart(newValue) {
            if (newValue == '') {
                this.deadlineStart = null
            }
        },
    },
    data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            pageNo: 1,
            columns: [{
                    label: this.$t('trademarkOpposition.logo'),
                    field: `logo`,
                },
                {
                    label: this.$t('trademarkOpposition.trademark_opposition_folder_no'),
                    field: `recordNo`,
                },
                {
                    label: this.$t('trademarkOpposition.application_no'),
                    field: `applicationNo`,
                },
                {
                    label: this.$t('trademarkOpposition.bulletin_no'),
                    field: `bulletinNo`,
                },
                {
                    label: this.$t('trademarkOpposition.bulletin_date'),
                    field: `bulletinDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('trademarkOpposition.name'),
                    field: `name`,
                },
                {
                    label: this.$t('trademarkOpposition.agent_name'),
                    field: `agent`,
                },
                {
                    label: this.$t('trademarkOpposition.owners'),
                    field: `ownerList`,
                },
                {
                    label: this.$t('trademarkOpposition.status'),
                    field: `trademarkOppositionStatus`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
            recordNo: '',
            attorneyName: '',
            agent: '',
            oppositionStatusSelect: 0,
            oppositionStatusOption: [],
            owner: '',
            applicationTrademark: '',
            classes: '',
            employeeSelect: 0,
            employeeOption: [],
            bulletinNoSelect: 0,
            bulletinNoOption: [],
            owner1: '',
            basisForOppositionTrademarkRecordNo: '',
            deadlineEnd: null,
            deadlineStart: null,
            basisForOpposition : '',
        }
    },
    mounted() {
        this.getTrademarkOppositionList()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'TrademarkOpposition'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getTrademarkOppositionList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getTrademarkOppositionList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.trademarkOppositionFormModal.showInfo(id)
        },
        getTrademarkOppositionList() {
            this.rows = []
            const controller = {
                name: 'TrademarkOpposition'
            }
            const data = {
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                recordNo: this.recordNo,
                attorneyName: this.attorneyName,
                client: this.agent,
                trademarkOppositionStatusId: this.oppositionStatusSelect,
                owner: this.owner,
                applicationNo: this.applicationTrademark,
                niceClass: this.classes,
                employeeId: this.employeeSelect,
                bulletinId: this.bulletinNoSelect,
                owner1: this.owner1,
                basisForOpposition: this.basisForOpposition,
                basisForOppositionTrademarkRecordNo: this.basisForOppositionTrademarkRecordNo,
                deadlineStart: this.deadlineStart,
                deadlineEnd: this.deadlineEnd
            }
            const payload = {
                data: data,
                controller: controller
            }
            let loader = this.$loading.show()
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    this.bulletinNoOption = response.data.bulletinList
                    this.employeeOption = response.data.employeeList
                    this.oppositionStatusOption = response.data.trademarkOppositionStatusList
                    this.bulletinNoOption.splice(0, 0, this.$nullSelected("no"))
                    this.employeeOption.splice(0, 0, this.$nullSelected())
                    this.oppositionStatusOption.splice(0, 0, this.$nullSelected())
                    response.data.trademarkOppositionList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
                loader.hide()
            })
        },
        exportExcel() {
            var controller = {
                name: 'TrademarkOpposition'
            }
            const data = {
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                recordNo: this.recordNo,
                attorneyName: this.attorneyName,
                client: this.agent,
                trademarkOppositionStatusId: this.oppositionStatusSelect,
                owner: this.owner,
                applicationNo: this.applicationTrademark,
                niceClass: this.classes,
                employeeId: this.employeeSelect,
                bulletinNoId: this.bulletinNoSelect,
                owner1: this.owner1,
                basisForOpposition: this.basisForOpposition,
                basisForOppositionTrademarkRecordNo: this.basisForOppositionTrademarkRecordNo,
                deadlineStart: this.deadlineStart,
                deadlineEnd: this.deadlineEnd
            }
            var payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    controller = {
                        name: 'TrademarkOpposition',
                        actionName: 'CanExport'
                    }
                    data = {
                        columnList: this.columns,
                        DataList: response.data.trademarkOppositionList
                    }
                    payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$downloadFile("Marka İtiraz", response.data.base64, 'xlsx')
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clearSearchForm() {
            this.recordNo = '';
            this.basisForOpposition = '';
            this.agent = '';
            this.owner = '';
            this.applicationTrademark = '';
            this.classes = '';
            this.employeeSelect = 0;
            this.bulletinNoSelect = 0;
            this.owner1 = '';
            this.basisForOppositionTrademarkRecordNo = '';
            this.deadlineStart = null;
            this.deadlineEnd = null;
            this.oppositionStatusSelect = 0;
            this.attorneyName = '',

            // Tabloyu yenile
            this.getTrademarkOppositionList();
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

[dir] .dark-layout .card {
    background-color: #252d3f !important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
}

.akista-d-flex {
    display: contents !important;
}

/* Solid border */
hr.solid {
    border-top: 3px solid #bbb;
}

.tableSizeTrademark {
    font-size: 14px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.featherIconReSize {
    width: 12px;
    height: 12px;
}

.mr-05 {
    margin-right: 0.5rem !important;
}

.custom-search {
    .btn {
        margin-left: 5px;
    }
}
</style>
