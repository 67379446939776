<template>
<div>
    <payment-form :getPaymentList="getPaymentList" ref="paymentFormModal"></payment-form>
    <div class="custom-search">
        <b-row>
            <b-col class="col-md-3">
                <label for="payment-sender-name-input">{{ $t('payment.sender_name') }}</label>
                <b-form>
                    <b-form-input @input="getPaymentList()" :placeholder="$t('payment.sender_name')" size="sm" id="payment-sender-name-input" v-model="senderName" maxlength="50" />
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <label for="payment-account-no-input">{{ $t('payment.account_no') }}</label>
                <b-form>
                    <b-form-input @input="getPaymentList()" :placeholder="$t('payment.account_no')" size="sm" id="payment-account-no-input" v-model="accountingNo" maxlength="50" />
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <label for="payment-invoice-no-input">{{ $t('payment.paid_invoice_no') }}</label>
                <b-form>
                    <b-form-input @input="getPaymentList()" :placeholder="$t('payment.paid_invoice_no')" size="sm" id="payment-invoice-no-input" type=number v-model="debitNo" maxlength="50" />
                </b-form>
            </b-col>
            <b-col class="col-md-3 custom-search d-flex justify-content-end mt-2">
                <div class="d-flex align-items-center">
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getPaymentList()" @click="getPaymentList()">{{ $t('payment.list') }}</b-button>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('payment.add') }}</b-button>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="clearSearchForm">{{ $t('others.clear') }}</b-button>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-collapse class="mb-1" accordion type="margin">
                    <app-collapse-item :title="$t('others.search')">
                        <b-row>
                            <b-col >
                                <b-form>
                                    <label for="payment-bank-input">{{ $t('payment.bank') }}</label>
                                    <b-form-select id="payment-bank-input" size="sm" v-model="bankSelect" value-field="id" text-field="name" :options="bankOption" />
                                </b-form>
                            </b-col>
                            <b-col >
                                <b-form>
                                    <label for="payment-jurisdiction-input">{{ $t('payment.jurisdiction') }}</label>
                                    <b-form-select id="payment-jurisdiction-input" size="sm" v-model="jurisdictionSelect" value-field="id" text-field="name" :options="jurisdictionOption" />
                                </b-form>
                            </b-col>
                            <b-col >
                                <b-form>
                                    <label for="payment-currency-input">{{ $t('payment.currency') }}</label>
                                    <b-form-select id="payment-currency-input" size="sm" v-model="currencySelect" value-field="id" text-field="name" :options="currencyOption" />
                                </b-form>
                            </b-col>
                            <b-col >
                                <b-form>
                                    <label for="payment-payment-method-type-input">{{ $t('payment.payment_method_type') }}</label>
                                    <b-form-select id="payment-payment-method-type-input" size="sm" v-model="paymentMethodTypeSelect" value-field="id" text-field="name" :options="paymentMethodTypeOption" />
                                </b-form>
                            </b-col>
                            <b-col >
                                <label for="payment-document-no-input">{{ $t('payment.document_no') }}</label>
                                <b-form>
                                    <b-form-input @input="getPaymentList()" :placeholder="$t('payment.document_no')" size="sm" id="payment-document-no-input" v-model="documentNo" maxlength="50" />
                                </b-form>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="mt-1">
                                <b-form>
                                    <label for="payment-payment-date-start-input">{{ $t('payment.payment_date_start') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="paymentDateStart"></CustomDatePicker>
                                </b-form>
                            </b-col>
                            <b-col class="mt-1">
                                <b-form>
                                    <label for="payment-payment-date-end-input">{{ $t('payment.payment_date_end') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="paymentDateEnd"></CustomDatePicker>
                                </b-form>
                            </b-col>
                            <b-col class="mt-1">
                                <b-form>
                                    <label for="payment-document-date-start-input">{{ $t('payment.document_date_start') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="documentDateStart"></CustomDatePicker>
                                </b-form>
                            </b-col>
                            <b-col class="mt-1">
                                <b-form>
                                    <label for="payment-document-date-end-input">{{ $t('payment.document_date_end') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="documentDateEnd"></CustomDatePicker>
                                </b-form>
                            </b-col>
                            <b-col class="mt-1">
                                <label for="payment-receipt-no-input">{{ $t('payment.receipt_no') }}</label>
                                <b-form>
                                    <b-form-input @input="getPaymentList()" :placeholder="$t('payment.receipt_no')" size="sm" id="payment-receipt-no-input" v-model="receiptNo" maxlength="50" />
                                </b-form>
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse>
            </b-col>
        </b-row>
    </div>
    <!-- table -->
    <vue-good-table id="paymentTable" ref="paymentTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" :pagination-options="{
        enabled: true,
        perPage: pageLength
      }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'paymentDate'" class="text-nowrap">
                {{ $t('payment.payment_date') }}
            </span>
            <span v-else-if="props.column.field === 'senderName'" class="text-nowrap">
                {{ $t('payment.sender_name') }}
            </span>
            <span v-else-if="props.column.field === 'documentNo'" class="text-nowrap">
                {{ $t('payment.document_no') }}
            </span>
            <span v-else-if="props.column.field === 'date'" class="text-nowrap">
                {{ $t('payment.date') }}
            </span>
            <span v-else-if="props.column.field === 'clientAccounting'" class="text-nowrap">
                {{ $t('payment.agent') }}
            </span>
            <span v-else-if="props.column.field === 'total'" class="text-nowrap">
                {{ $t('payment.amount') }}
            </span>
            <span v-else-if="props.column.field === 'totalAmount'" class="text-nowrap">
                {{ $t('payment.total') }}
            </span>
            <span v-else-if="props.column.field === 'invoiceNoList'">
                {{ $t('payment.paid_invoice_no') }}
            </span>
            <span v-else-if="props.column.field === 'cost'" class="text-nowrap">
                {{ $t('payment.cost') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'paymentDate'">
                <b-row>
                    <b-col class="font-size: xx-small">
                        <span>{{ $formatFn(props.row.paymentDate) }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small"><strong>{{ props.row.paymentMethodType }}</strong></span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'senderName'">
                <b-row>
                    <b-col class="font-size: xx-small">
                        <span>{{ props.row.senderName }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small"><strong>{{ props.row.clientBank }}</strong></span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'documentDate'">
                <b-row>
                    <b-col class="font-size: xx-small">
                        <span>{{ props.row.documentNo }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small"><strong>{{ $formatFn(props.row.documentDate) }}</strong></span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'date'">
                <b-row>
                    <b-col class="font-size: xx-small">
                        <span>{{ $formatFn(props.row.date) }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small"><strong>{{ $formatFn(props.row.instructionDate) }}</strong></span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'clientAccounting'">
                <b-row>
                    <b-col class="font-size: xx-small">
                        <span>{{ props.row.clientAccounting }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'totalAmount'">
                <b-row>
                    <b-col class="font-size: xx-small">
                        <span>{{ $formatMoney(props.row.total + props.row.cost + props.row.commission )}}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small"><strong>{{ props.row.currency }}</strong></span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'total'">
                <b-row>
                    <b-col class="font-size: xx-small">
                        <span>{{ $formatMoney(props.row.total) }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'cost'">
                <b-row>
                    <b-col class="font-size: xx-small">
                        <span><strong>{{ $t('payment.cost') }}:</strong> {{ props.row.cost }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span><strong>{{ $t('payment.comission') }}:</strong> {{ props.row.commission }}</span>
                    </b-col>
                </b-row>
            </span>
            <!-- Column: Process -->
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                        <feather-icon icon="EditIcon" />
                    </b-button>

                    <b-button v-if="props.row.isTransfer == false" v-b-tooltip.html :title="$t('others.Transfer')" v-b-modal.modal-top variant="success" class="btn-icon mb-1 ml-1" size="sm" @click="approvalInvoice(props.row.id)">
                        <feather-icon icon="CompassIcon" />
                    </b-button>

                    <b-button v-if="props.row.paymentMethodTypeId == 4" v-b-tooltip.html :title="$t('others.print_check_order')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="downloadCheckPdf(props.row.id)">
                        <feather-icon icon="FileTextIcon" />
                    </b-button>

                    <b-button v-if="props.row.isTransfer == false" v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select size="sm" v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getPaymentList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            pageNo: 1,
            columns: [{
                    label: this.$t('payment.payment_date'),
                    field: `paymentDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('payment.sender_name'),
                    field: `senderName`,
                },
                {
                    label: this.$t('payment.document_no'),
                    field: `documentDate`,
                },
                {
                    label: this.$t('payment.date'),
                    field: `date`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('payment.agent'),
                    field: `clientAccounting`,
                },
                {
                    label: this.$t('payment.amount'),
                    field: `total`,
                    type: 'number',
                },
                {
                    label: this.$t('payment.total'),
                    field: `totalAmount`,
                    sortable: false,
                },
                {
                    label: this.$t('payment.paid_invoice_no'),
                    field: `invoiceNoList`,
                },
                {
                    label: this.$t('payment.cost'),
                    field: `cost`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    sortable: false,
                },
            ],
            rows: [],
            searchTerm: '',
            senderName: '',
            receiptNo: '',
            documentNo: '',
            bankSelect: 0,
            bankOption: [],
            jurisdictionSelect: 0,
            jurisdictionOption: [],
            currencySelect: 0,
            currencyOption: [],
            paymentMethodTypeSelect: 0,
            paymentMethodTypeOption: [],
            paymentDateStart: null,
            paymentDateEnd: null,
            documentDateStart: null,
            documentDateEnd: null,
            accountingNo: '',
            debitNo: '',
        }
    },
    mounted() {
        this.getPaymentList()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'Payment'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getPaymentList()
                        } else {
                            this.$CantDeletePaidPayment()
                            this.getPaymentList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.paymentFormModal.showInfo(id)
        },
        getPaymentList() {
            this.rows = []
            const controller = {
                name: 'Payment'
            }
            const data = {
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                senderName: this.senderName,
                receiptNo: this.receiptNo,
                documentNo: this.documentNo,
                paymentDateStart: this.paymentDateStart,
                paymentDateEnd: this.paymentDateEnd,
                documentDateStart: this.documentDateStart,
                documentDateEnd: this.documentDateEnd,
                currencyId: this.currencyId,
                jurisdictionId: this.jurisdictionId,
                bankId: this.bankId,
                paymentMethodTypeId: this.paymentMethodTypeSelect,
                accountingNo: this.accountingNo,
                debitNo: this.debitNo
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    response.data.paymentList.forEach(element => {
                        this.rows.push(element)
                    })
                    this.paymentMethodTypeOption = response.data.paymentMethodTypeList
                    this.paymentMethodTypeOption.splice(0, 0, this.$nullSelected()),
                        this.currencyOption = response.data.currencyList
                    this.currencyOption.splice(0, 0, this.$nullSelected()),
                        this.jurisdictionOption = response.data.jurisdictionList
                    this.jurisdictionOption.splice(0, 0, this.$nullSelected())
                    this.bankOption = response.data.bankList
                    this.bankOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        approvalInvoice(id) {
            this.$PrivateConfirm(this.$t("payment.confirmTitle"), this.$t("payment.confirmMessage"), "warning", this.$t("payment.confirmButtonText"), this.$t("payment.cancelButtonText")).then(result => {
                if (result.value) {

                    const data = {
                        paymentId: id,
                        employeId: localStorage.getItem('EmployeeId')
                    }
                    const controller = {
                        name: 'InvoiceStatement',
                        actionName: 'ControlAndSaveStatement'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }

                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getPaymentList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'Payment',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Departmanlar", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        async downloadCheckPdf(id) {
            const data = {
                id: id,
            }
            const controller = {
                name: 'Payment',
                actionName: 'DownloadCheckPdf'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile(response.data.document.name, response.data.base64, response.data.document.type)
                } else {
                    this.$CustomWarningAlert(response.data.resultMessage)
                }
            })
        },
        clearSearchForm() {
            // Form alanlarını temizle
            this.senderName = '';
            this.accountingNo = '';
            this.debitNo = '';
            this.paymentDateStart = null;
            this.paymentDateEnd = null;
            this.receiptNo = '',
            this.documentNo = '',
            this.bankSelect = 0,
            this.jurisdictionSelect = 0,
            this.currencySelect = 0,
            this.paymentMethodTypeSelect = 0,
            this.documentDateStart = null,
            this.documentDateEnd = null,
            this.debitNo = '',

            // Tabloyu yenile
            this.getPaymentList();
        }
    },
    computed: {
        hasSearchValues() {
            console.log('Form values:', {
                senderName: this.senderName,
                accountingNo: this.accountingNo,
                debitNo: this.debitNo,
                paymentDateStart: this.paymentDateStart,
                paymentDateEnd: this.paymentDateEnd
            });
            
            return Boolean(
                this.senderName ||
                this.accountingNo ||
                this.debitNo ||
                this.paymentDateStart ||
                this.paymentDateEnd
            );
        }
    }
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}

.mr-05 {
    margin-right: 0.5rem !important;
}

.custom-search {
    .btn {
        margin-left: 5px;
    }
}

.ml-1 {
    margin-left: 0.5rem !important;
}
</style>
