<template>
<div>
    <!-- modal -->
    <b-modal id="jobUpdateNoteFormModal" centered size="lg" no-close-on-backdrop ref="jobUpdateNoteFormModal" :title=" $t('jobUpdateNote.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <b-form>
            <label for="job-note-input">{{ $t('jobUpdateNote.note') }}</label>
            <b-form-group>
                <b-row>
                    <b-col>
                        <b-form-textarea id="job-note-input" maxlength="1000" :placeholder=" $t('designObjectionToUs.note')" v-model="note" rows="3" />
                    </b-col>
                </b-row>
            </b-form-group>
        </b-form>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getJobDetailList: {
            type: Function
        },
        getJobReportList: {
            type: Function
        },
        recordId: {
            type: Number
        },
    },
    data() {
        return {
            id: 0,
            note: '',
            jobId: 0,
        }
    },
    methods: {
        showInfo(id, jobId) {
            this.$refs['jobUpdateNoteFormModal'].show()
            this.id = id
            this.jobId = jobId
            const data = {
                id: this.id,
                jobId: jobId
            }
            const controller = {
                name: 'JobHistory'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.note = response.data.jobHistoryDto.note
                } else {
                    this.$bvModal.hide('jobUpdateNoteFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                jobHistoryId: this.id,
                note: this.note
            }
            const controller = {
                name: 'JobHistory',
                actionName: 'UpdateJobHistoryNote'

            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {

                    if(this.getJobDetailList != null)
                        this.getJobDetailList(this.jobId)

                    if(this.getJobReportList != null)
                        this.getJobReportList()

                    this.$bvModal.hide('jobUpdateNoteFormModal')
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
