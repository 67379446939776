<template>
<div>
    <!-- modal -->
    <b-modal id="translatorPaymentFormModal" @close="getTranslatorPaymentList()" centered size="xl" no-close-on-backdrop ref="translatorPaymentFormModal" :title=" $t('translatorPayment.info')" hide-footer>
        <div class="custom-search d-flex justify-content mb-1">
            <b-row style="margin-left: auto;">
                <b-col>
                    <b-button variant="outline-primary" size="sm" class="mr-1" @click.prevent="addOrUpdate">
                        {{$t('others.ok_title')}}
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <b-row>
            <b-col class="col-md-3">
                <b-form>
                    <validation-provider :name=" $t('translatorPayment.translator')" :rules="{ excluded:0}" v-slot="{ errors }">
                        <label for="translator-payment-translator-input">{{ $t('translatorPayment.translator') }}</label>
                        <b-form-select :state="errors.length > 0 ? false:null" size="sm" v-model="translatorSelect" value-field="id" text-field="translator" :options="translatorOption" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <label for="translator-payment-report-date-input">{{ $t('translatorPayment.report_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="reportDate"></CustomDatePicker>
            </b-col>
            <b-col class="col-md-3">
                <label for="translator-payment-payment-date-input">{{ $t('translatorPayment.payment_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="paymentDate"></CustomDatePicker>
            </b-col>
            <b-col class="col-md-3">
                <b-form>
                    <label for="translator-payment-total-array-input">{{ $t('translatorPayment.total_array') }}</label>
                    <b-form-group>
                        <b-form-input id="translator-payment-total-array-input" type="number" size="sm" v-model="wordPrice" disabled />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-3">
                <b-form>
                    <label for="translator-payment-total-word-input">{{ $t('translatorPayment.total_word') }}</label>
                    <b-form-group>
                        <b-form-input id="translator-payment-total-word-input" size="sm" type="number" v-model="pagePrice" disabled />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <b-form>
                    <label for="translator-payment-total-image-input">{{ $t('translatorPayment.total_image') }}</label>
                    <b-form-group>
                        <b-form-input id="translator-payment-total-image-input" size="sm" type="number" v-model="imagePrice" disabled />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <b-form>
                    <label for="translator-payment-total-formula-input">{{ $t('translatorPayment.total_formula') }}</label>
                    <b-form-group>
                        <b-form-input id="translator-payment-total-formula-input" size="sm" type="number" v-model="formulaPrice" disabled />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-md-3">
                <b-form>
                    <label for="translator-payment-total-table-input">{{ $t('translatorPayment.total_table') }}</label>
                    <b-form-group>
                        <b-form-input id="translator-payment-total-table-input" size="sm" type="number" v-model="tablePrice" disabled />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab active :title=" $t('translatorPayment.translations')" style="width:100%;">
                            <b-card-text>
                                <translator-payment-translations-list ref="translatorPaymentTranslationsTable" :getTranslatorPaymentList="getTranslatorPaymentList"></translator-payment-translations-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    watch: {
        translatorSelect(newValue, oldValue) {
            if (newValue == null || oldValue == null) {
                newValue = 0
                oldValue = 0
            }
            if (newValue == undefined || oldValue == undefined) {
                newValue = 0
                oldValue = 0
            }
            if (newValue !== oldValue) {
                this.translatorOption.forEach(element => {
                    if (newValue == element.id) {
                        this.wordPrice = element.wordPrice
                        this.pagePrice = element.pagePrice
                        this.imagePrice = element.imagePrice
                        this.formulaPrice = element.formulaPrice
                        this.tablePrice = element.tablePrice
                    }
                })
            }
        }
    },
    props: {
        getTranslatorPaymentList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            translatorSelect: 0,
            translatorOption: [],
            pagePrice: 0,
            imagePrice: 0,
            formulaPrice: 0,
            tablePrice: 0,
            wordPrice: 0,
            reportDate: null,
            paymentDate: null,
            hiddenControl: true,
        }
    },
    methods: {
        showInfo(id) {
            this.$refs['translatorPaymentFormModal'].show()
            this.id = id
            if (this.id == 0)
                this.hiddenControl = true
            else
                this.hiddenControl = false
            const data = {
                id: this.id
            }
            const controller = {
                name: 'TranslatorPayment'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.translatorOption = response.data.translatorPriceList
                    this.pagePrice = response.data.translatorPayment.pagePrice
                    this.imagePrice = response.data.translatorPayment.imagePrice
                    this.formulaPrice = response.data.translatorPayment.formulaPrice
                    this.tablePrice = response.data.translatorPayment.tablePrice
                    this.wordPrice = response.data.translatorPayment.wordPrice
                    this.reportDate = response.data.translatorPayment.reportDate
                    this.paymentDate = response.data.translatorPayment.paymentDate
                    if(response.data.translatorPayment.translatorId > 0){
                        this.translatorSelect = response.data.translatorPayment.translatorId
                    }
                    else {
                        this.translatorSelect = 0
                    }
                    this.translatorOption.splice(0, 0, this.$nullSelected('translator'))
                } else {
                    this.$bvModal.hide('translatorPaymentFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
                this.$refs.translatorPaymentTranslationsTable.getTranslatorPaymentTranslationsList(this.id, this.translatorSelect)
            })
        },
        addOrUpdate() {
            const data = {
                translatorPayment: {
                    id: this.id,
                    reportDate: this.reportDate,
                    paymentDate: this.paymentDate,
                    wordPrice: this.wordPrice,
                    tablePrice: this.tablePrice,
                    formulaPrice: this.formulaPrice,
                    imagePrice: this.imagePrice,
                    pagePrice: this.pagePrice,
                    translatorId: this.translatorSelect
                }
            }
            const controller = {
                name: 'TranslatorPayment'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.getTranslatorPaymentList()
                    this.$SaveAlert()
                    if (this.id == 0) {
                        this.id = response.data.translatorPaymentId
                        this.hiddenControl = false
                        this.$refs.translatorPaymentTranslationsTable.getTranslatorPaymentTranslationsList(this.id, this.translatorSelect)
                    }
                    if(response.data.resultMessage == 'TranslatePaymentDoneCantUpdatePaymentId'){
                        this.$TranslatePaymentCantUpdate()
                    }
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .dark-layout .modal .modal-content,
[dir] .dark-layout .modal .modal-body,
[dir] .dark-layout .modal .modal-footer {
    background-color: #272e3f !important;
    border-color: #3b4253;
}

[dir] body.dark-layout .b-calendar-grid.form-control .small {
    background: #161d31;
    display: none !important;
}

.b-calendar .b-calendar-grid-help {
    color: #6e6b7b !important;
    display: none;
}

.uploading-image {
    display: flex;
    width: 15em;
    height: 10em;
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
