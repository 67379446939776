<template>
<div>
    <!-- modal -->
    <b-modal id="accountingBooksFormModal" centered size="xl" no-close-on-backdrop ref="accountingBooksFormModal" :title=" $t('accountingBooks.info')" hide-footer>
        <b-row>
            <b-col class="mt-2">
                <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                    <b-tab :title=" $t('accountingBooks.receipt')" active style="width:100%;">
                        <b-card-text>
                            <accounting-books-receipt-list :subsidiaryLedgerId="this.id" :getAccountingBooksList="getAccountingBooksList"></accounting-books-receipt-list>
                        </b-card-text>
                    </b-tab>
                    <b-tab :title=" $t('accountingBooks.accounts')" style="width:100%;">
                        <b-card-text>
                            <accounting-books-accounts-list :subsidiaryLedgerId="this.id" :getAccountingBooksList="getAccountingBooksList"></accounting-books-accounts-list>
                        </b-card-text>
                    </b-tab>
                    <b-tab :title=" $t('accountingBooks.business_partner')" style="width:100%;">
                        <b-card-text>
                            <business-partner-list :subsidiaryLedgerId="this.id"></business-partner-list>
                        </b-card-text>
                    </b-tab>
                    <b-tab :title=" $t('accountingBooks.accounting_transaction_type')" style="width:100%;">
                        <b-card-text>
                            <accounting-transaction-type-list :subsidiaryLedgerId="this.id" :getAccountingBooksList="getAccountingBooksList"></accounting-transaction-type-list>
                        </b-card-text>
                    </b-tab>
                    <b-tab :title=" $t('accountingBooks.Accounting Transaction Doesnt Balanced Entities Report')" style="width:100%;">
                        <b-card-text>
                            <accounting-transaction-doesnt-balanced-entities-report-list :subsidiaryLedgerId="this.id" :getAccountingBooksList="getAccountingBooksList"></accounting-transaction-doesnt-balanced-entities-report-list>
                        </b-card-text>
                    </b-tab>
                    <b-tab :title=" $t('accountingBooks.Accounting Transaction Ledger Report')" style="width:100%;">
                        <b-card-text>
                            <accounting-transaction-ledger-report-list :subsidiaryLedgerId="this.id" :getAccountingBooksList="getAccountingBooksList"></accounting-transaction-ledger-report-list>
                        </b-card-text>
                    </b-tab>
                    <b-tab :title=" $t('accountingBooks.Zero Sequence No Report')" style="width:100%;">
                        <b-card-text>
                            <accounting-transaction-zero-sequence-no-report-list :subsidiaryLedgerId="this.id" :getAccountingBooksList="getAccountingBooksList"></accounting-transaction-zero-sequence-no-report-list>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

  
<script>
export default {
    props: {
        getAccountingBooksList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
        }
    },
    methods: {
        showInfo(id) {
            this.$refs['accountingBooksFormModal'].show()
            this.id = id
        },
    }
}
</script>
  
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
