<template>
<div>
    <mail-send-form @mailEmitValue="mailEmitValueFunction" ref="mailSendForm"></mail-send-form>
    <patent-form ref="patentForm"></patent-form>
    <b-row class="align-items-center mb-1">
        <b-col class="d-flex align-items-center col-lg-8">
            <b-col>
                <label for="domain-owner-input">{{ $t('patentAnnualFeeReminder.month') }}</label>
                <b-form-select v-model="monthSelect" @change="getPatentAnnualFeeReminderList()" v-on:keyup.enter="getPatentAnnualFeeReminderList()" size="sm" value-field="id" text-field="name" :options="monthOption" />
            </b-col>
            <b-col>
                <label>{{ $t('patentAnnualFeeReminder.year') }}</label>
                <b-form-select v-model="yearSelect" @change="getPatentAnnualFeeReminderList()" v-on:keyup.enter="getPatentAnnualFeeReminderList()" size="sm" value-field="id" text-field="id" :options="yearOption" />
            </b-col>
            <b-col>
                <label for="patentAnnualFeeReminder-detail-list-input">{{ $t('patentAnnualFeeReminder.detail_list') }}</label>
                <br>
                <b-form-checkbox class="custom-control-success" name="patentAnnualFeeReminder-detail-input" id="patentAnnualFeeReminder-detail-list-input" v-model="isDetailList" @change="changeView()" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
        </b-col>

        <b-col class="d-flex justify-content-end col-lg-4 mt-2">
            <b-button style="float:right;" class="d-inline-block mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getPatentAnnualFeeReminderList()" @click="getPatentAnnualFeeReminderList()">{{ $t('domain.list') }}</b-button>
            <b-button style="float:right;" class="d-inline-block" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="patentAnnualFeeReminderTable" ref="patentAnnualFeeReminderTable" :columns="columns" :rows="rows" :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" styleClass="vgt-table condensed tableSize" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
  }" :pagination-options="{
    enabled: true,
    perPage: pageLength
  }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'applicationNo'" class="text-nowrap">
                {{ $t('patentAnnualFeeReminder.application_no') }}
                <br />
                {{ $t('patentAnnualFeeReminder.application_date') }}
            </span>
            <span v-else-if="props.column.field === 'epApplicationNo'" class="text-nowrap">
                {{ $t('patentAnnualFeeReminder.epApplication_no') }}
                <br />
                {{ $t('patentAnnualFeeReminder.epApplication_date') }}
            </span>
            <span v-else-if="props.column.field === 'pctApplicationNo'" class="text-nowrap">
                {{ $t('patentAnnualFeeReminder.pctApplication_no') }}
                <br />
                {{ $t('patentAnnualFeeReminder.pctApplication_date') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'applicationNo'">
                <span>
                    {{ props.row.applicationNo }}
                    <br />
                    {{ $formatFn(props.row.applicationDate) }}
                </span>
            </span>
            <span v-else-if="props.column.field === 'epApplicationNo'">
                <span>
                    {{ props.row.epApplicationNo }}
                    <br />
                    {{ $formatFn(props.row.epApplicationDate) }}
                </span>
            </span>
            <span v-else-if="props.column.field === 'pctApplicationNo'">
                <span>
                    {{ props.row.pctApplicationNo }}
                    <br />
                    {{ $formatFn(props.row.pctApplicationDate) }}
                </span>
            </span>
            <span v-else-if="props.column.field==='explanation'">
                {{ props.row.patentAnnuityStatus }}
                <br />
                {{ props.row.explanation }}
            </span>
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.patentId)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <span v-if="props.row.jobCheckForMail == false && props.row.isMailSent == false">
                        <b-button v-b-tooltip.html :title="$t('patentCertificate.send_certificate')" variant="success" class="btn-icon mb-1 ml-1" size="sm" @click="showMailForm(props.row.patentAnnuityId,props.row.patentId)">
                            <feather-icon icon="MailIcon" />
                        </b-button>
                    </span>
                </span>
            </span>
        </template>
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }} </span>
                    <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    props: {
        patentId: Number,
        getPatentList: {
            type: Function
        },
    },
    data() {
        return {
            dir: false,
            columns: [],
            defaultColumns: [{
                    label: this.$t('patentAnnualFeeReminder.recordNo'),
                    field: 'recordNo',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.application_no'),
                    field: 'applicationNo',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.epApplication_no'),
                    field: 'epApplicationNo',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.pctApplication_no'),
                    field: 'pctApplicationNo',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.patent_annual_date'),
                    field: 'date',
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('patentAnnualFeeReminder.agent'),
                    field: 'agent',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.patent_annuity_will_pay'),
                    field: 'patentAnnuityWillPay',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.patent_annuity_note'),
                    field: 'explanation',
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            detailColumns: [{
                    label: this.$t('patentAnnualFeeReminder.recordNo'),
                    field: 'recordNo',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.patent_status'),
                    field: 'patentStatus',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.application_no'),
                    field: 'applicationNo',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.patent_no'),
                    field: 'patentNo',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.epApplication_no'),
                    field: 'epApplicationNo',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.pctApplication_no'),
                    field: 'pctApplicationNo',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.year'),
                    field: 'annuityYear',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.patent_annual_date'),
                    field: 'date',
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('patentAnnualFeeReminder.agent'),
                    field: 'agent',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.agentNote'),
                    field: 'agentNote',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.renewalAgent'),
                    field: 'renewalAgent',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.renewalAgentNote'),
                    field: 'renewalAgentNote',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.patent_annuity_will_pay'),
                    field: 'patentAnnuityWillPay',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.patent_annuity_note'),
                    field: 'explanation',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.patentNote'),
                    field: 'patentNote',
                },
                {
                    label: this.$t('patentAnnualFeeReminder.agentEmail'),
                    field: 'agentEmail',
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
            item: 0,
            yearSelect: 0,
            yearOption: [],
            monthSelect: 0,
            monthOption: [],
            pageLength: 10,
            pageNo: 0,
            isDetailList: false
        }
    },
    mounted() {
        this.changeView()
        this.getPatentAnnualFeeReminderList()
    },
    methods: {
        async changeView() {
            if (this.isDetailList)
                this.columns = this.detailColumns
            else
                this.columns = this.defaultColumns
        },
        async getInfo(patentId) {
            this.$refs.patentForm.showInfo(patentId)
        },
        getPatentAnnualFeeReminderList() {
            this.item = 0
            this.rows = []
            const data = {
                month: this.monthSelect,
                year: this.yearSelect
            }
            const controller = {
                name: 'PatentAnnuity',
                actionName: 'GetPatentAnnuityRemindingList'
            }
            const payload = {
                controller: controller,
                data: data
            }
            let loader = this.$loading.show()
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.monthOption = response.data.monthList
                    this.yearOption = response.data.years
                    this.monthOption.splice(0, 0, this.$nullSelected())
                    this.yearOption.splice(0, 0, this.$nullSelected())
                    this.monthSelect = response.data.month
                    this.yearSelect = response.data.year
                    response.data.patentAnnuityReminderReportList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
                loader.hide()
            })
        },
        showMailForm(patentAnnuityId, patentId) {
            const data = {
                Id: patentAnnuityId
            }
            const controller = {
                name: 'MailTemplate',
                actionName: 'PreparePatentAnnualFeeReminderMail'
            }
            const payload = {
                controller: controller,
                data: data
            }

            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$refs.mailSendForm.showInfo(response.data, patentId, patentAnnuityId)
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            var controller = {
                name: 'PatentAnnuity',
                actionName: 'CanExport'
            }

            var data = {
                columnList: this.detailColumns,
                dataList: this.rows
            }
            var payload = {
                data: data,
                controller: controller
            }
            let loader = this.$loading.show()
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Reminder Listesi", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
                loader.hide()
            })
        },
        mailEmitValueFunction(mailEmitValue) {
            if (mailEmitValue.recordIdForAnnuityMail > 0) {
                var controller = {
                    name: 'PatentAnnuity',
                    actionName: 'ChangeMailSendStatusForPatentAnnuity'
                }
                var data = {
                    patentAnnuityId: mailEmitValue.recordIdForAnnuityMail,
                    mailSendStatus: true
                }
                var payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        //Lets Create Job and Job History Record    
                        var controller2 = {
                            name: 'Job',
                            actionName: 'SaveJobAfterReminderMailForPatentAnnuity'
                        }

                        var data2 = {
                            moduleId: 4,
                            recordId: mailEmitValue.recordId,
                            employeeId: localStorage.getItem('EmployeeId')
                        }
                        var payload2 = {
                            data: data2,
                            controller: controller2
                        }
                        this.$store.dispatch('moduleAuth/customService', payload2).then((response2) => {
                            if (response.data.resultStatus == true) {
                                this.getPatentAnnualFeeReminderList()
                            }
                        })
                    }
                })
            }
        }
    },
}
</script>

<style lang="scss">
.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
