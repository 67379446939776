<template>
<div>
    <invoice-form :getInvoiceList="getInvoiceList" ref="invoiceFormModal"></invoice-form>
    <invoice-add-caution-form :getInvoiceList="getInvoiceList" ref="invoiceAddCautionFormModal"></invoice-add-caution-form>
    <div class="custom-search">
        <b-row>
            <b-col class="col-md-2">
                <label for="invoice-debit-no-input">{{ $t('invoice.debitNo') }}</label>
                <b-form-input size="sm" v-model="debitNo" v-on:keyup.enter="getInvoiceList()" :placeholder="$t('invoice.debitNo')" type="text" class="d-inline-block" />
            </b-col>
            <b-col class="col-md-2">
                <label for="invoice-reference-no-input">{{ $t('invoice.ref_no') }}</label>
                <b-form-input size="sm" v-model="recordNo" v-on:keyup.enter="getInvoiceList()" :placeholder="$t('invoice.ref_no')" type="text" class="d-inline-block" />
            </b-col>
            <b-col class="col-md-2">
                <label for="invoice-agent-name-input">{{ $t('invoice.agent_name') }}</label>
                <b-form-input size="sm" v-model="agentName" v-on:keyup.enter="getInvoiceList()" :placeholder="$t('invoice.agent_name')" type="text" class="d-inline-block" />
            </b-col>
            <b-col class="col-md-2">
                <b-form>
                    <label for="invoice-employee-input">{{ $t('invoice.employee') }}</label>
                    <b-form-select id="invoice-employee-input" :placeholder="$t('invoice.employee')" size="sm" v-model="employeeSelect" value-field="id" text-field="name" :options="employeeOption" />
                </b-form>
            </b-col>
            <b-col class="col-2">
                <b-form-group>
                    <label for="invoice-payment-status-input">{{ $t('invoice.payment_status') }}</label>
                    <div class="d-flex align-items-center">
                        <b-form-select id="invoice-payment-status-input" @change="getInvoiceList()" :placeholder="$t('invoice.payment_status')" size="sm" v-model="invoicePaymentStatusSelect" value-field="id" text-field="name" :options="invoicePaymentStatusOption" />
                    </div>
                </b-form-group>
            </b-col>
            <b-col class="col-md-2 custom-search d-flex justify-content-end mt-2">
                <div class="d-flex align-items-center">
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getInvoiceList()" @click="getInvoiceList()">{{ $t('invoice.list') }}</b-button>
                    <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="clearSearchForm"> {{ $t('others.clear') }}</b-button>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-collapse class="mb-1 mt-1" accordion type="margin">
                    <app-collapse-item :title="$t('others.search')">
                        <b-row>
                            <b-col class="col-2">
                                <b-form-group>
                                    <label for="invoice-header-input">{{ $t('invoice.invoice_title') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input size="sm" v-model="header" v-on:keyup.enter="getInvoiceList()" :placeholder="$t('invoice.invoice_title')" type="text" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-2">
                                <b-form-group>
                                    <label for="invoice-account-no-input">{{ $t('invoice.account_no') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-input size="sm" v-model="accountNo" v-on:keyup.enter="getInvoiceList()" :placeholder="$t('invoice.account_no')" type="text" class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-2">
                                <b-form-group>
                                    <label for="invoice-module-input">{{ $t('invoice.module') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-select id="invoice-module-input" @change="moduleChange()" :placeholder="$t('invoice.module')" size="sm" v-model="moduleSelect" value-field="id" text-field="name" :options="moduleOption" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-2">
                                <b-form-group>
                                    <label for="invoice-service-input">{{ $t('invoice.service') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-select id="invoice-service-input" :placeholder="$t('invoice.service')" size="sm" v-model="serviceSelect" value-field="id" text-field="name" :options="serviceOption" />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-2">
                                <b-form-group>
                                    <label for="invoice-status-input">{{ $t('invoice.status') }}</label>
                                    <div class="d-flex align-items-center">
                                        <b-form-select id="invoice-status-input" :placeholder="$t('invoice.status')" size="sm" v-model="invoiceStatusSelect" value-field="id" text-field="name" :options="invoiceStatusOption" />
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-2">
                                <b-form-group>
                                    <label for="invoice-proforma-date-start-input">{{ $t('invoice.proforma_date_start') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="proformaDateStart"></CustomDatePicker>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-2">
                                <b-form-group>
                                    <label for="invoice-proforma-date-end-input">{{ $t('invoice.proforma_date_end') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="proformaDateEnd"></CustomDatePicker>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-2">
                                <label for="invoice-amount-min-input">{{ $t('invoice.amount_min') }}</label>
                                <div class="d-flex align-items-center">
                                    <b-form-input size="sm" v-model="amountMin" v-on:keyup.enter="getInvoiceList()" :placeholder="$t('invoice.amount_min')" type="number" class="d-inline-block" />
                                </div>
                            </b-col>
                            <b-col class="col-2">
                                <label for="invoice-amount-max-input">{{ $t('invoice.amount_max') }}</label>
                                <div class="d-flex align-items-center">
                                    <b-form-input size="sm" v-model="amountMax" type="number" v-on:keyup.enter="getInvoiceList()" :placeholder="$t('invoice.amount_max')" class="d-inline-block" />
                                </div>
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse>
            </b-col>
        </b-row>
    </div>
    <!-- table -->
    <vue-good-table id="invoiceTable" ref="invoiceTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
      enabled: true,
      externalQuery: searchTerm
    }" :select-options="{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }" :pagination-options="{
      enabled: true,
      perPage: pageLength
    }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'debitNo'" class="text-nowrap">
                {{ $t('invoice.debitNo') }}
            </span>
            <span v-else-if="props.column.field === 'total'" class="text-nowrap">
                {{ $t('invoice.amount') }}
            </span>
            <span v-else-if="props.column.field === 'proformaDate'">
                {{ $t('invoice.proforma_date') }}
            </span>
            <span v-else-if="props.column.field === 'invoiceNo'">
                {{ $t('invoice.invoice_no_date') }}
            </span>
            <span v-else-if="props.column.field === 'isPaid'" class="text-nowrap">
                {{ $t('invoice.is_paid') }}
            </span>
            <span v-else-if="props.column.field === 'invoiceStatus'" class="text-nowrap">
                {{ $t('invoice.status') }}
            </span>
            <span v-else-if="props.column.field === 'employee'" class="text-nowrap">
                {{ $t('invoice.employee') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'client'">
                <span>
                    {{ props.row.client }}
                </span>
                <br>
                <span style="font-size: smaller;">
                    {{ props.row.clientAccounting }}
                </span>
            </span>
            <span v-else-if="props.column.field === 'total'">
                <span>
                    {{ $formatMoney(props.row.total) }}
                </span>
            </span>
            <span v-else-if="props.column.field === 'proformaDate'">
                <span>
                    {{$formatFn(props.row.proformaDate) }}
                </span>
            </span>
            <span v-else-if="props.column.field === 'invoiceNo'">
                <span>
                    {{props.row.invoiceNo }}
                </span>
                <br>
                <span style="font-size: smaller;">
                    {{$formatFn(props.row.invoiceDate) }}
                </span>
            </span>
            <span v-else-if="props.column.field === 'isPaid'">
                <span>
                    <b-form-checkbox disabled v-model="props.row.isPaid" />
                </span>
            </span>
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-05" size="sm" @click="getInfo(props.row.id)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <!-- STOCK-1326 -->
                    <b-button v-if="props.row.invoiceStatusId > 1" v-b-tooltip.html :title="$t('others.caution')" v-b-modal.modal-top :variant="props.row.invoiceCautionId > 0 ?'success':'primary'" class="btn-icon mb-1 ml-05" size="sm" @click="setCautionToInvoice(props.row.invoiceCautionId,props.row.id)">
                        <feather-icon icon="BellIcon" />
                    </b-button>
                    <!-- End Of Code-->
                    <b-button v-if="props.row.invoiceStatusId < 2" v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-05" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select size="sm" v-model="pageLength" :options="['10','50']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getInvoiceList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            pageNo: 1,
            columns: [{
                    label: this.$t('invoice.debitNo'),
                    field: `debitNo`,
                },
                {
                    label: this.$t('invoice.stock_ref_no'),
                    field: `stockReferenceNo`,
                },
                {
                    label: this.$t('invoice.agent_name_accounting_no'),
                    field: `client`,
                },
                {
                    label: this.$t('invoice.amount'),
                    field: `total`,
                },
                {
                    label: this.$t('invoice.currency_code'),
                    field: `currencyCode`,
                },
                {
                    label: this.$t('invoice.proforma_date'),
                    field: `proformaDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('invoice.invoice_no_date'),
                    field: `invoiceNo`,
                },
                {
                    label: this.$t('invoice.is_paid'),
                    field: `isPaid`,
                },
                {
                    label: this.$t('invoice.status'),
                    field: `invoiceStatus`,
                },
                {
                    label: this.$t('invoice.employee'),
                    field: `employee`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
            debitNo: '',
            recordNo: '',
            agentName: '',
            employeeSelect: 0,
            employeeOption: [],
            accountNo: '',
            moduleSelect: 0,
            moduleOption: [],
            serviceSelect: 0,
            serviceOption: [],
            invoiceStatusSelect: 0,
            invoiceStatusOption: [],
            proformaDateStart: null,
            proformaDateEnd: null,
            amountMin: 0,
            amountMax: 0,
            invoicePaymentStatusOption: [],
            invoicePaymentStatusSelect: 0,
            count: null,
            header: ''
        }
    },
    mounted() {
        this.getInvoiceList()
    },
    methods: {
        async deleted(data) {
            this.count = data.invoiceDetailCount
            data = {
                id: data.id,
                isChangeInvoice: false
            }
            const controller = {
                name: 'Invoice'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteInvoiceConfirm(this.count).then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getInvoiceList()
                        } else {
                            if (response.data.resultMessage == 'ThereIsAInvoiceDetailInThisRecord') {
                                this.$InvoiceDetailError()
                            } else {
                                this.$CustomWarningAlert(response.data.resultMessage)
                            }
                            this.getInvoiceList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.invoiceFormModal.showInfo(id)
        },
        async setCautionToInvoice(id,invoiceId) {
            if(id > 1){
                this.$refs.invoiceAddCautionFormModal.showInfo(id,invoiceId)
            }
            else {
                const data = {
                    id: 0,
                    note: "Bu Faturanın ödemesi yapılmıştır.",
                    employeeId: localStorage.getItem('EmployeeId'),
                    invoiceId: invoiceId
                }
                const controller = {
                    name: 'InvoiceCaution'
                }
                const payload = {
                    data: data,
                    controller: controller
                }

                this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$SaveAlert()
                        this.getInvoiceList()
                    } else {
                        this.$CustomWarningAlert(response.data.resultMessage)
                    }
                })
            }
            
        },
        moduleChange() {
            const controller = {
                name: 'Service',
                actionName: 'GetServiceListByModuleId'
            }
            const data = {
                moduleId: this.moduleSelect
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                this.serviceOption = response.data.serviceList
                this.serviceOption.splice(0, 0, this.$nullSelected())
            })
        },
        getInvoiceList() {
            this.rows = []
            const controller = {
                name: 'Invoice'
            }
            const data = {
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                debitNo: this.debitNo,
                recordNo: this.recordNo,
                agentName: this.agentName,
                employeeId: this.employeeSelect,
                accountNo: this.accountNo,
                moduleId: this.moduleSelect,
                serviceId: this.serviceSelect,
                invoiceStatusId: this.invoiceStatusSelect,
                proformaDateStart: this.proformaDateStart,
                proformaDateEnd: this.proformaDateEnd,
                amountMin: this.amountMin,
                amountMax: this.amountMax,
                invoicePaymentStatus: this.invoicePaymentStatusSelect,
                header: this.header
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    response.data.invoiceList.forEach(element => {
                        this.rows.push(element)
                    })
                    this.proformaTypeOption = response.data.proformaTypeList
                    this.proformaTypeOption.splice(0, 0, this.$nullSelected()),
                        this.invoiceStatusOption = response.data.invoiceStatusList
                    this.invoiceStatusOption.splice(0, 0, this.$nullSelected()),
                        this.billingStatusOption = response.data.billingStatusList
                    this.billingStatusOption.splice(0, 0, this.$nullSelected())
                    this.employeeOption = response.data.employeeList
                    this.employeeOption.splice(0, 0, this.$nullSelected())
                    this.moduleOption = response.data.moduleList
                    this.moduleOption.splice(0, 0, this.$nullSelected())
                    this.invoicePaymentStatusOption = response.data.paymentStatusList
                    this.invoicePaymentStatusOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'Invoice',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Departmanlar", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clearSearchForm() {
            // Form alanlarını temizle
            this.debitNo = '';
            this.recordNo = '';
            this.agentName = '';
            this.employeeSelect = 0;
            this.invoicePaymentStatusSelect = 0;
            this.invoiceDateStart = null;
            this.invoiceDateEnd = null;
            this.dueDateStart = null;
            this.dueDateEnd = null;
            this.paymentDateStart = null;
            this.paymentDateEnd = null;
            this.accountNo = '',
            this.moduleSelect = 0,
            this.serviceSelect = 0,
            this.invoiceStatusSelect = 0,
            this.proformaDateStart = null,
            this.proformaDateEnd = null,
            this.amountMin = 0,
            this.amountMax = 0,
            this.header = ''

            // Tabloyu yenile
            this.getInvoiceList();
        }
    },
    computed: {
        hasSearchValues() {
            console.log('Form values:', {
                debitNo: this.debitNo,
                recordNo: this.recordNo,
                agentName: this.agentName,
                employeeSelect: this.employeeSelect,
                invoicePaymentStatusSelect: this.invoicePaymentStatusSelect
            });
            
            return Boolean(
                this.debitNo ||
                this.recordNo ||
                this.agentName ||
                (this.employeeSelect && this.employeeSelect !== 0) ||
                (this.invoicePaymentStatusSelect && this.invoicePaymentStatusSelect !== 0) ||
                this.invoiceDateStart ||
                this.invoiceDateEnd ||
                this.dueDateStart ||
                this.dueDateEnd ||
                this.paymentDateStart ||
                this.paymentDateEnd
            );
        }
    }
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
