<template>
<div>
    <!-- modal -->
    <b-modal id="jobNextFormModal" centered size="lg" no-close-on-backdrop ref="jobNextFormModal" :title="$t('jobNext.job_next_info') + ' : ' + this.recordNo" :cancel-title="$t('others.cancel_title')" :ok-title="$t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-lg-11">
                    <b-form>
                        <validation-provider :name="$t('job.job_status_path')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                            <label for="job-next-status-relation-detail-input">{{
                  $t("jobNext.job")
                }}</label>
                            <b-form-select @change="giveDates()" size="sm" v-model="jobNextStatusRelationDetailSelect" value-field="id" text-field="name" :options="jobNextStatusRelationDetailOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col class="col-lg-1">
                    <b-form>
                        <br />
                        <b-button variant="outline-primary" size="sm" v-b-tooltip.html :title="$t('others.close_job')" @click="closeJob()">
                            <feather-icon icon="CheckSquareIcon" size="13" class="align-middle" />
                        </b-button>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-4 mt-1">
                    <b-form>
                        <label for="job-next-date-input">{{ $t("jobNext.date") }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="date"></CustomDatePicker>
                    </b-form>
                </b-col>
                <b-col class="col-lg-4 mt-1">
                    <b-form>
                        <label for="job-next-next-job-deadline-input">{{
                $t("jobNext.next_job_deadline")
              }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="nextJobDeadline"></CustomDatePicker>
                    </b-form>
                </b-col>
                <b-col class="col-lg-4 mt-1">
                    <b-form>
                        <label for="job-next-tpmk-deadline-input">{{
                $t("jobNext.tpmk_deadline")
              }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="tpmkDeadline"></CustomDatePicker>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-6 mt-1" v-if="hiddenCheckDocumentNo">
                    <label for="job-next-document-entry-no-input">{{
              $t("jobNext.document_entry_no")
            }}</label>
                    <b-form-group>
                        <validation-provider #default="{ errors }" :name="$t('jobNext.document_entry_no')" :rules="isRequiredDocumentNo ? 'required' : ''">
                            <b-form-input id="job-next-document-entry-no-input" size="sm" v-model="documentNo" maxlength="50" :state="errors.length > 0 ? false : null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <b-col class="col-lg-6 mt-1">
                    <b-form>
                        <label for="job-next-employee-input">{{
                $t("jobNext.employee")
              }}</label>
                        <b-form-select size="sm" v-model="employeeSelect" value-field="id" text-field="name" :options="employeeOption" />
                    </b-form>
                </b-col>
            </b-row>
            <b-row v-if="hiddenCheck">
                <b-col class="col-lg-6 mt-1">
                    <label for="job-next-application-no-input">{{
              $t("jobNext.application_no")
            }}</label>
                    <b-form-group>
                        <validation-provider #default="{ errors }" :name="$t('jobNext.application_no')" :rules="isRequired2 ? 'required' : ''">
                            <b-form-input id="job-next-application-no-input" size="sm" v-model="applicationNo" maxlength="12" :state="errors.length > 0 ? false : null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-12">
                    <b-form>
                        <label for="job-next-note-input">{{ $t("jobNext.note") }}</label>
                        <b-form-textarea size="sm" id="job-next-note-input" v-model="note" rows="3" max-rows="8" maxlength="250" />
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getJobList: {
            type: Function,
        },
        getJobReportList: {
            type: Function,
        },
        recordId: {
            type: Number,
        },
    },
    data() {
        return {
            id: 0,
            moduleId: 0,
            jobNextStatusRelationDetailOption: [],
            jobNextStatusRelationDetailSelect: 0,
            nextJobDeadline: null,
            tpmkDeadline: null,
            date: null,
            employeeOption: [],
            employeeSelect: 0,
            note: "",
            jobId: 0,
            documentId: 0,
            documentNo: "",
            isRequiredDocumentNo: false,
            hiddenCheckDocumentNo: false,
            applicationNo: "",
            hiddenCheck: true,
            isRequired2: false,
            recordNo: "",
            formerJobHistoryTmpkDeadline: null,
        };
    },
    methods: {
        giveDates() {
            this.nextJobDeadline = null;
            this.tpmkDeadline = null;
            const data = {
                jobId: this.jobId,
                jobStatusRelationId: this.jobNextStatusRelationDetailSelect,
                jobHistoryDate: this.date,
            };
            const controller = {
                name: "JobHistory",
                actionName: "CalculateNextDateByJobStatusRelationId",
            };
            const payload = {
                data: data,
                controller: controller,
            };
            this.$store
                .dispatch("moduleAuth/customService", payload)
                .then((response) => {
                    this.nextJobDeadline = response.data.nextJobReminderDate;
                    this.tpmkDeadline = response.data.tpmkReminderDate;
                    //STOCK-1036 TPE-OUT And Module Check 
                    // //Changelog : This Method changed with new Attribute on JobStatus named  : isApplicationNoRequired by STOCK-1544. 
                    if (response.data.applicationCheck == true) {
                        this.hiddenCheck = true;
                        this.isRequired2 = true;
                    } else {
                        this.hiddenCheck = false;
                        this.isRequired2 = false;
                    }
                    //
                });

            let jobRelation = this.jobNextStatusRelationDetailOption.find(
                (p) => p.id == this.jobNextStatusRelationDetailSelect
            );

            if (jobRelation != null && jobRelation.jobStatusPathId == 6) {
                this.isRequiredDocumentNo = true;
                this.hiddenCheckDocumentNo = true;
            } else {
                this.isRequiredDocumentNo = false;
                this.hiddenCheckDocumentNo = false;
            }

            if (this.tpmkDeadline == null) {
                this.tpmkDeadline = this.formerJobHistoryTmpkDeadline
            }
        },
        showInfo(jobId, documentId, moduleId, recordNo) {
            this.$refs["jobNextFormModal"].show();
            this.jobId = jobId;
            this.documentId = documentId;
            this.moduleId = moduleId;
            this.recordNo = recordNo;
            const data = {
                id: 0,
                jobId: jobId,
            };
            const controller = {
                name: "JobHistory",
            };
            const payload = {
                data: data,
                controller: controller,
            };
            this.$store.dispatch("moduleAuth/getInfo", payload).then((response) => {
                if (response.data.resultStatus == true) {
                    if (response.data.jobHistoryDto.date != null) {
                        this.date = response.data.jobHistoryDto.date;
                    } else this.date = new Date();
                    if (response.data.jobStatusList != null) {
                        this.jobNextStatusRelationDetailOption = response.data.jobStatusList;
                        if (response.data.jobStatusList.length == 1 && response.data.jobHistoryDto.jobStatusRelationId == 0) {
                            this.jobNextStatusRelationDetailSelect = response.data.jobStatusList[0].id;
                        } else {
                            this.jobNextStatusRelationDetailSelect = response.data.jobHistoryDto.jobStatusRelationId;
                        }
                        this.giveDates()
                    }
                    this.documentNo = response.data.jobHistoryDto.documentNo;
                    this.jobNextStatusRelationDetailOption.splice(0, 0, this.$nullSelected());
                    this.employeeOption = response.data.employeeList;
                    this.employeeOption.splice(0, 0, this.$nullSelected());
                    if (response.data.jobHistoryDto.employeeId != 0) {
                        this.employeeSelect = response.data.jobHistoryDto.employeeId;
                    } else {
                        this.employeeSelect = localStorage.getItem("EmployeeId");
                    }
                    this.note = response.data.jobHistoryDto.note;
                    this.formerJobHistoryTmpkDeadline = response.data.formerJobHistoryTmpkDeadline
                    if (this.tpmkDeadline == null) {
                        this.tpmkDeadline = this.formerJobHistoryTmpkDeadline
                    }
                } else {
                    this.$bvModal.hide("jobNextFormModal");
                    this.$WarningAlert(response.data.resultMessage);
                }
            });
        },
        clear() {
            this.hiddenCheck = true;
            this.isRequired2 = false;
            this.applicationNo = "";
            this.hiddenCheckDocumentNo = true;
            this.isRequiredDocumentNo = false;
        },
        addOrUpdate() {
            const data = {
                jobStatusRelationId: this.jobNextStatusRelationDetailSelect,
                employeeId: this.employeeSelect,
                note: this.note,
                nextJobDeadline: this.nextJobDeadline,
                tpmkDeadline: this.tpmkDeadline,
                date: this.date,
                jobId: this.jobId,
                documentId: this.documentId,
                documentNo: this.documentNo,
            };
            const controller = {
                name: "JobHistory",
            };
            const payload = {
                data: data,
                controller: controller,
            };
            this.$refs.simpleRules.validate().then((success) => {
                if (success) {
                    this.$store.dispatch("moduleAuth/save", payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide("jobNextFormModal");
                            if (this.getJobList != null) {
                                this.getJobList();
                            }
                            if (this.getJobReportList != null) {
                                this.getJobReportList();
                            }
                            if (this.hiddenCheck == true) {
                                const data = {
                                    moduleId: this.moduleId,
                                    recordId: this.recordId,
                                    applicationNo: this.applicationNo,
                                    processDate: this.date,
                                };
                                const controller = {
                                    name: "JobHistory",
                                    actionName: "UpdateRecordWhenJobApplicationAndMuvOut",
                                };
                                const payload = {
                                    data: data,
                                    controller: controller,
                                };
                                this.$store
                                    .dispatch("moduleAuth/customService", payload)
                                    .then((response) => {
                                        if (response.data.resultStatus != true) {
                                            this.$WarningAlert(response.data.resultMessage);
                                        }
                                    });
                            }
                            this.$bvModal.hide("jobNextFormModal");
                            this.$SaveAlert();
                            this.clear();
                        } else {
                            this.$WarningAlert(response.data.resultMessage);
                        }
                    });
                }
            });
        },
        async closeJob(id) {
            const data = {
                job: {
                    id: this.jobId,
                    isDone: true,
                },
            };
            const controller = {
                name: "Job",
                actionName: "CloseJob",
            };
            const payload = {
                data: data,
                controller: controller,
            };

            this.$PrivateConfirm(
                this.$t("jobNext.confirmTitle"),
                this.$t("jobNext.confirmMessage"),
                "warning",
                this.$t("jobNext.confirmButtonText"),
                this.$t("jobNext.cancelButtonText")
            ).then((result) => {
                if (result.value) {
                    this.$store
                        .dispatch("moduleAuth/customService", payload)
                        .then((response) => {
                            if (response.data.resultStatus == true) {
                                this.$SaveAlert();
                                if (this.getJobList != null) {
                                    this.getJobList();
                                }
                                if (this.getJobReportList != null) {
                                    this.getJobReportList();
                                }
                            } else {
                                this.$WarningAlert(response.data.resultMessage);
                                if (this.getJobList != null) {
                                    this.getJobList();
                                }
                                if (this.getJobReportList != null) {
                                    this.getJobReportList();
                                }
                            }
                        });
                }
            });
        },
    },
};
</script>

<style lang="scss">
[dir="ltr"] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
